import React, { useState, useMemo, useEffect } from "react"
import { Box, Button, Autocomplete, type TextFieldProps } from "@mui/material"
import { Add, RemoveCircle } from "@mui/icons-material"

import { Controller, useForm } from "react-hook-form"
import { getStepData } from "../../utils"
import { type Node } from "reactflow"
import {
  AddEntryButton,
  CustomizedTextField,
  EntriesWrapper,
  EntryCard,
  FieldsWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  TypographyOne,
  TypographyTwo,
  Wrapper,
  DeleteIconButton,
} from "./styled"
import { getChannelConfigurationsAPI } from "../../../../../../services"
import { useQuery } from "@tanstack/react-query"

interface IUpdateDatabaseProps {
  selectedAction: INodeItem
  nodes: Node[]
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: any | any[],
    key: string,
    changeFromController?: (...input: any[]) => void,
  ) => void
}

interface ITableItem {
  description: string
  example: string
  required?: string
  title: string
  type: string
}

export const UpdateDatabase: React.FC<IUpdateDatabaseProps> = (props) => {
  const { selectedAction, nodes, handleChange } = props

  const { control, getValues, unregister, setValue } = useForm<any>()
  const [currentExpanded, setCurrentExpanded] = useState<string>("")

  const { data: updateDatabaseData } = useQuery({
    queryKey: ["update-database"],
    queryFn: () => getChannelConfigurationsAPI("Interface"),
    refetchOnMount: true,
  })

  const tables: Record<string, Record<string, ITableItem>> | undefined =
    useMemo(
      () =>
        updateDatabaseData
          ?.map((channel) => {
            return channel?.apiSetup?.apiSetupDetails.reduce(
              (acc, detail: any) => {
                const source = {
                  [detail.title]: {
                    ...JSON.parse(detail?.jsonResponseSchema),
                    method: detail.methodType,
                    apiSetupId: channel.apiSetup?.id,
                  },
                }

                return source[detail.title].method === "PUT"
                  ? { ...acc, ...source }
                  : acc
              },
              {},
            )
          })
          .reduce((acc, method) => {
            return { ...acc, ...method }
          }, {}),
      [updateDatabaseData],
    )

  useEffect(() => {
    if (tables !== undefined) {
      Object.keys(tables)?.forEach((table) => {
        const previousValues = Object.values(rowsAdded[table])?.reduce(
          (acc: any, row: any) => {
            return { ...acc, ...row }
          },
          {},
        )
        Object.entries(previousValues).forEach((pair) =>
          setValue(pair[0], pair[1]),
        )
      })
    }
  }, [tables])

  const initialRowsAdded = useMemo(() => {
    if (tables !== undefined && tables !== null) {
      return Object.keys(tables)?.reduce((acc: any, table) => {
        acc[table] = []
        return acc
      }, {})
    } else {
      return { "Claim Costs Data": [] }
    }
  }, [tables])

  const [rowsAdded, setRowsAdded] = useState<Record<string, any[]>>(
    selectedAction?.inputs?.RequestJson || initialRowsAdded,
  )

  const getDataStepSelectedFields =
    getStepData(nodes, "GetDataStep")?.inputs?.SelectedFields || {}

  const deleteExistingFields: (tableName: string) => void = (tableName) => {
    rowsAdded[tableName].forEach((el) =>
      Object.keys(el).forEach((field) => {
        unregister(field)
      }),
    )
    handleChange(selectedAction, "input", { [tableName]: [] }, "RequestJson")
    setRowsAdded((prev) => {
      return { ...prev, [tableName]: [] }
    })
  }

  const handleRowChange: (tableName: string, j: number) => void = (
    tableName,
    j,
  ) => {
    const values = getValues()

    setRowsAdded((prev) => {
      const length =
        prev[tableName]?.length === 0
          ? String(prev[tableName]?.length)
          : String(prev[tableName]?.length - 1)

      const row = Object.keys(values)
        .filter(
          (key: string) =>
            key.split("-")[0] === tableName &&
            key.slice(key.lastIndexOf("-") + 1) === length,
        )
        .reduce((acc: Record<string, any>, key: string) => {
          acc[key] = values[key]
          return acc
        }, {})

      if (prev[tableName]?.length === 0) {
        return {
          ...prev,
          [tableName]: [row],
        }
      } else {
        return {
          ...prev,
          [tableName]: prev[tableName]?.map((existingRow, i) => {
            return i === j ? row : existingRow
          }),
        }
      }
    })
  }

  const onAddRow = (tableName: string) => {
    if (rowsAdded[tableName]?.length === 0) {
      return
    }
    setRowsAdded((prev) => {
      const addedRow = Object.keys(
        prev[tableName][prev[tableName]?.length - 1],
      ).reduce((acc: any, key: string) => {
        const arr = key.split("-")
        const rowNumber = Number(arr[arr.length - 1]) + 1
        const newKey = [arr.slice(0, arr.length - 1), rowNumber]
          .join(",")
          .replaceAll(",", "-")
        acc[newKey] = undefined
        return acc
      }, {})

      return {
        ...prev,
        [tableName]: [...prev[tableName], addedRow],
      }
    })
  }

  const deleteRow: (tableName: string, j: number) => void = (tableName, j) => {
    const initialValues = getValues()
    const values = Object.keys(initialValues)

    setRowsAdded((prev) => {
      const fieldsToEdit = values.filter((field) => {
        return Number(field.split("-")[field.split("-").length - 1]) > j
      })

      fieldsToEdit.forEach((field) => {
        const newEnd = Number(field.split("-")[field.split("-").length - 1]) - 1
        setValue(
          `${field
            .split("-")
            .slice(0, field.split("-").length - 1)
            .join("-")}-${newEnd}`,
          getValues()[field],
        )
      })
      const toDelete = prev[tableName].filter(
        (_, index) => index === prev[tableName].length - 1,
      )
      const rowsToUpdate = prev[tableName].filter((_, index) => index > j)
      const updatedRows = rowsToUpdate.map((row) => {
        const oldKeys = Object.keys(row)
        const newKeys = oldKeys.map((oldKey) => {
          const keyArr = oldKey.split("-")
          const newEnd = Number(keyArr[keyArr.length - 1]) - 1
          return `${keyArr.slice(0, keyArr.length - 1).join("-")}-${newEnd}`
        })

        const updated: any = {}
        newKeys.map(
          (newKey: string, i: number) =>
            (updated[newKey] = initialValues[oldKeys[i]]),
        )
        return updated
      })
      Object.keys(toDelete[0]).forEach((field) => unregister(field))
      return {
        ...prev,
        [tableName]: [...prev[tableName].slice(0, j), ...updatedRows],
      }
    })
  }

  const destructuredSelectedFields: any = useMemo(() => {
    return Object.values(getDataStepSelectedFields)?.reduce(
      (acc: any, field: any) => {
        return { ...acc, ...field }
      },
      {},
    )
  }, [getDataStepSelectedFields])

  return (
    <Box width="100%">
      <Box>
        {tables !== undefined &&
          Object.keys(tables)?.map((tableName: string, i) => {
            return (
              <Wrapper key={i}>
                <StyledAccordion
                  expanded={currentExpanded === tableName}
                  tableName={tableName}
                  currentExpanded={currentExpanded}
                >
                  <StyledAccordionSummary
                    onClick={() =>
                      setCurrentExpanded((prev) =>
                        prev !== tableName ? tableName : "",
                      )
                    }
                    tableName={tableName}
                    currentExpanded={currentExpanded}
                    rowsAdded={rowsAdded}
                  >
                    {tableName}
                  </StyledAccordionSummary>
                  <StyledAccordionDetails
                    rowsAdded={rowsAdded}
                    tableName={tableName}
                  >
                    <EntriesWrapper>
                      {Array.from({
                        length:
                          rowsAdded[tableName]?.length === 0 ||
                          rowsAdded[tableName] === undefined
                            ? 1
                            : rowsAdded[tableName]?.length,
                      }).map((el, j) => {
                        return (
                          <EntryCard key={`${tableName}-box-${j}`}>
                            {Object.keys(tables[tableName]).map((field, i) => {
                              return (
                                tables?.[tableName]?.[field]?.required && (
                                  <FieldsWrapper
                                    key={`${tableName}-fieldWrapper-${i}`}
                                  >
                                    <TypographyOne>
                                      {tables?.[tableName]?.[field]?.title ||
                                        "No title found"}
                                    </TypographyOne>
                                    <TypographyTwo>
                                      {tables?.[tableName]?.[field]
                                        ?.description || "No description"}
                                    </TypographyTwo>
                                    <Box
                                      position="relative"
                                      key={`${tableName}-${field}-${i}-${j}`}
                                      width="100%"
                                    >
                                      <Controller
                                        control={control}
                                        name={`${tableName}-${field}-${i}-${j}`}
                                        render={({
                                          field: { value, onChange },
                                        }) => {
                                          const options = Object.keys(
                                            destructuredSelectedFields,
                                          ).map((field) =>
                                            destructuredSelectedFields[field]
                                              ?.selected
                                              ? {
                                                  value: field,
                                                  label:
                                                    destructuredSelectedFields[
                                                      field
                                                    ].title,
                                                }
                                              : null,
                                          )
                                          return (
                                            <Autocomplete
                                              freeSolo
                                              value={
                                                options.find(
                                                  (option) =>
                                                    option?.value === value,
                                                ) ||
                                                value ||
                                                ""
                                              }
                                              onChange={(_, newValue) => {
                                                if (
                                                  typeof newValue === "string"
                                                ) {
                                                  onChange(newValue)
                                                } else if (newValue) {
                                                  onChange(newValue.value)
                                                } else {
                                                  onChange(null)
                                                }
                                                handleRowChange(tableName, j)
                                              }}
                                              disablePortal
                                              options={options.filter(
                                                (option) => option !== null,
                                              )}
                                              getOptionLabel={(option) =>
                                                typeof option === "string"
                                                  ? option
                                                  : option?.label
                                              }
                                              filterOptions={(
                                                options,
                                                params,
                                              ) => {
                                                const filtered = options.filter(
                                                  (option) =>
                                                    option?.value
                                                      ?.toLowerCase()
                                                      .includes(
                                                        params.inputValue.toLowerCase(),
                                                      ),
                                                )

                                                if (params.inputValue !== "") {
                                                  filtered.push(
                                                    params.inputValue,
                                                  )
                                                }

                                                return filtered
                                              }}
                                              sx={{
                                                width: "100%",
                                                mb: 1.5,
                                                zIndex: "9999 !important",
                                                overflow: "visible",
                                              }}
                                              renderInput={(params) => (
                                                <CustomizedTextField
                                                  {...(params as TextFieldProps)}
                                                  {...params}
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                  }}
                                                  InputLabelProps={{
                                                    sx: {
                                                      fontSize: "12.5px",
                                                      mt: -0.5,
                                                    },
                                                  }}
                                                  value={value || ""}
                                                  label="Selected Field"
                                                />
                                              )}
                                            />
                                          )
                                        }}
                                      />
                                    </Box>
                                    {rowsAdded[tableName]?.length > 1 && (
                                      <DeleteIconButton
                                        onClick={() => {
                                          deleteRow(tableName, j)
                                        }}
                                        color="error"
                                      >
                                        <RemoveCircle />
                                      </DeleteIconButton>
                                    )}
                                  </FieldsWrapper>
                                )
                              )
                            })}
                          </EntryCard>
                        )
                      })}
                      <AddEntryButton onClick={() => onAddRow(tableName)}>
                        <Add />
                      </AddEntryButton>
                    </EntriesWrapper>
                    <Box display="flex" justifyContent="flex-end" gap="8px">
                      <Button
                        onClick={() => {
                          setCurrentExpanded("")
                          deleteExistingFields(tableName)
                        }}
                        sx={{ height: "30px" }}
                        variant="outlined"
                      >
                        Discard
                      </Button>
                      <Button
                        onClick={() => {
                          setCurrentExpanded("")
                          handleChange(
                            selectedAction,
                            "input",
                            {
                              [tableName]: rowsAdded[tableName],
                              ApiSetupId: tables[tableName].apiSetupId,
                            },
                            "RequestJson",
                          )
                        }}
                        sx={{ height: "30px" }}
                      >
                        Apply
                      </Button>
                    </Box>
                  </StyledAccordionDetails>
                </StyledAccordion>
              </Wrapper>
            )
          })}
      </Box>
    </Box>
  )
}
