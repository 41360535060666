import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import React, { useState, useCallback, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useMutation, useQuery } from "@tanstack/react-query"

import {
  Box,
  Typography,
  Switch,
  TextField,
  FormControlLabel,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import { CalendarMonthOutlined, PersonOutline } from "@mui/icons-material"

import { useToast } from "../../../../contexts"
import { DeleteDefinitionModal, VersionManagementTable } from "./components"
import {
  deleteWorkflowDefinitionAPI,
  activateDefinitionVersion,
} from "../../../../services"
import {
  ComponentWrapper,
  Container,
  TitleBox,
  SectionBox,
  DeleteButton,
  InformationItem,
} from "./styled"

interface IProps {
  modifiedData: IWorkflowDefinitionRes
  setModifiedData: React.Dispatch<React.SetStateAction<IWorkflowDefinitionRes>>
}

export const PropertiesTab = (props: IProps) => {
  const { modifiedData, setModifiedData } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()

  const { breakpoints } = useTheme()
  const isSmallerThanMd = useMediaQuery(breakpoints.down("md"))

  const { control } = useForm<any>()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const {
    mutate: mutateDeleteDefinition,
    isPending: isDeleteDefinitionPending,
  } = useMutation({
    mutationFn: () =>
      deleteWorkflowDefinitionAPI(modifiedData.id, {
        version: modifiedData.version,
      }),
    onSuccess: () => {
      toast.show(t("definitionDeletedSuccessfully"), "success")
      navigate("/workflow-definitions")
    },
  })

  const {
    mutate: mutateDefinitionActivation,
    isPending: isDefinitionActivating,
  } = useMutation({
    mutationFn: () =>
      activateDefinitionVersion(modifiedData.id, modifiedData.version),
  })

  const { data: definitionVersions } =
    useQuery<IGetWorkflowDefinitionsResponse>({
      queryKey: ["definition-versions"],
    })

  const deleteDefinition = useCallback(() => {
    mutateDeleteDefinition()
  }, [])

  const changeActivity = useCallback(() => {
    if (!modifiedData.active) {
      mutateDefinitionActivation()
      setModifiedData((prev) => {
        return {
          ...prev,
          active: true,
        }
      })
    } else
      setModifiedData((prev) => {
        return {
          ...prev,
          active: false,
        }
      })
  }, [modifiedData])

  const currentRunningVersion = useMemo(
    () => definitionVersions?.items.find((item) => item.active)?.version ?? 0,
    [definitionVersions],
  )

  console.log("modifiedData", modifiedData)

  return (
    <ComponentWrapper>
      <Container>
        <TitleBox>
          <Typography variant="h4">{t("definitionProperties")}</Typography>
          <Box display="flex" alignItems="center">
            {isDefinitionActivating && <CircularProgress size={16} />}
            <FormControlLabel
              label={modifiedData?.active ? "Active" : "Inactive"}
              labelPlacement="start"
              disabled={isDefinitionActivating}
              control={
                <Switch
                  checked={modifiedData.active}
                  onChange={changeActivity}
                />
              }
            />
          </Box>
        </TitleBox>
        <Box
          display="flex"
          flexDirection={isSmallerThanMd ? "column" : "row"}
          gap="24px"
        >
          <SectionBox>
            <Typography variant="h6" margin="16px 0">
              {t("details")}
            </Typography>
            <Controller
              control={control}
              name="workflow-definition-name"
              render={({ field: { onChange } }) => {
                return (
                  <TextField
                    value={modifiedData.name}
                    label={t("definitionName")}
                    onChange={(e) =>
                      onChange(
                        setModifiedData((prev) => {
                          return {
                            ...prev,
                            name: e.target.value,
                          }
                        }),
                      )
                    }
                  />
                )
              }}
            />
            <Box height="16px" />
            <Controller
              control={control}
              name="workflow-definition-description"
              render={({ field: { onChange } }) => {
                return (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={modifiedData.description}
                    label="Description"
                    onChange={(e) =>
                      onChange(
                        setModifiedData((prev) => {
                          return {
                            ...prev,
                            description: e.target.value,
                          }
                        }),
                      )
                    }
                  />
                )
              }}
            />
          </SectionBox>
          <SectionBox>
            <Box display="flex" flexDirection="column" gap="12px">
              <Typography variant="h6" mt="16px">
                {t("information")}
              </Typography>
              <Typography variant="largeBold">
                {t("currentRunningVersion")}:{" "}
                <Typography variant="large">{currentRunningVersion}</Typography>
              </Typography>
              <Box display="flex" gap="8px" alignItems="center">
                <Typography variant="largeBold">{t("created")}:</Typography>
                <InformationItem>
                  <CalendarMonthOutlined fontSize="small" />
                  {modifiedData.createdAt
                    ? dayjs(modifiedData.createdAt).format("DD.MM.YYYY, HH:mm")
                    : "-"}
                </InformationItem>
                <InformationItem>
                  <PersonOutline fontSize="small" />
                  {modifiedData.createdBy !== null
                    ? `${modifiedData.createdBy.name || ""} ${modifiedData.createdBy.surname || ""}`.trim() || "-"
                    : "-"}
                </InformationItem>
              </Box>
              <Box display="flex" gap="8px">
                <Typography variant="largeBold">{t("lastUpdated")}:</Typography>
                <InformationItem>
                  <CalendarMonthOutlined fontSize="small" />
                  {modifiedData.updatedAt
                    ? dayjs(new Date(modifiedData.updatedAt)).format(
                        "DD.MM.YYYY, HH:mm",
                      )
                    : "-"}
                </InformationItem>
                <InformationItem>
                  <PersonOutline fontSize="small" />
                  {modifiedData.updatedBy !== null
                    ? `${modifiedData.updatedBy.name || ""} ${modifiedData.updatedBy.surname || ""}`.trim() || "-"
                    : "-"}
                </InformationItem>
              </Box>
            </Box>
          </SectionBox>
        </Box>
        <Typography variant="h6" mt="24px">
          {t("versionManagement")}
        </Typography>
        <VersionManagementTable />
        <DeleteButton
          onClick={() => setIsDeleteModalOpen(true)}
          color="error"
          variant="outlined"
        >
          <DeleteOutlineIcon />
          {t("deleteDefinition")}
        </DeleteButton>
      </Container>
      <DeleteDefinitionModal
        isOpen={isDeleteModalOpen}
        isDeleting={isDeleteDefinitionPending}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => deleteDefinition()}
      />
    </ComponentWrapper>
  )
}
