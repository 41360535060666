export const CARD_WIDTH = 400
export const CARD_HEIGHT = 40

export const TRIGGER_METHODS: IMethodItem[] = [
  {
    type: "trigger",
    action: "IncomingEmail",
    label: "Incoming Email",
    icon: "incomingEmail",
    shorthand: "IE",
  },
  {
    type: "trigger",
    action: "ClaimProperty",
    label: "Claim Property",
    icon: "incomingEmail",
    shorthand: "CP",
  },
  {
    type: "trigger",
    action: "GenericTrigger",
    label: "Generic Trigger",
    icon: "incomingEmail",
    shorthand: "GT",
  },
]
export const ACTION_METHODS: IMethodItem[] = [
  {
    type: "posteingang",
    action: "PosteingangStep",
    label: "Advoware General Inbox",
    icon: "inbox",
    shorthand: "PE",
  },
  {
    type: "wiedervorlage",
    action: "WiedervorlageStep",
    label: "Advoware Resubmission",
    icon: "postAdd",
    shorthand: "WV",
  },
  {
    type: "genericstep",
    action: "UpdateCostTblStep",
    label: "Update Cost Table",
    icon: "table",
    shorthand: "UC",
  },
  {
    type: "postausgang",
    action: "PostausgangStep",
    label: "Advoware Outbox",
    icon: "outgoingEmail",
    shorthand: "PA",
  },
  {
    type: "action",
    action: "GetDataStep",
    label: "Get Data",
    icon: "data",
    shorthand: "GD",
  },
  {
    type: "genericstep",
    action: "TemplateBuilderStep",
    label: "Generate Document",
    icon: "outgoingEmail",
    shorthand: "TB",
  },
  {
    type: "genericstep",
    action: "UpdateDatabaseStep",
    label: "Update Database",
    icon: "database",
    shorthand: "UD",
  },
]
export const CORE_METHODS: IMethodItem[] = [
  {
    type: "core",
    action: "WaitFor",
    label: "WaitFor",
    icon: "pausePresentation",
    shorthand: "WF",
  },
  {
    type: "core",
    action: "EmitLog",
    label: "Emit Log",
    icon: "terminal",
    shorthand: "EL",
  },
  {
    type: "core",
    action: "If",
    label: "If Condition",
    icon: "splitArrows",
    shorthand: "IF",
  },
  {
    type: "core",
    action: "EndWorkflow",
    label: "End Workflow",
    icon: "outlinedFlag",
    shorthand: "EW",
  },
]
