import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  TableFooter,
  Typography,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  ClickAwayListener,
  Box,
  Skeleton,
} from "@mui/material"
import { EnhancedTableHead, EnhancedTableRow } from "./components"
import {
  EnhancedTable,
  EmptyRow,
  NoDataRow,
  EnhancedTableContainer,
} from "./styled"
import { CustomRow } from "./components/EnhancedTableRow/styled"

type Order = "asc" | "desc"

interface IClaimTableProps {
  data: IGetGlobalInboxResponse | undefined
  isPending: boolean
  isRefetching: boolean
  page: number
  rowsPerPage: number
  order: Order
  orderBy: keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail
  disableEditing?: boolean
  updatePage: (page: number) => void
  updateRowsPerPage: (rowsPerPage: number) => void
  updateOrder: (order: Order) => void
  updateOrderBy: (
    orderBy: keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail,
  ) => void
}

export const EmailDataTable = (props: IClaimTableProps) => {
  const {
    data,
    isPending,
    isRefetching,
    page,
    rowsPerPage,
    order,
    orderBy,
    disableEditing,
    updatePage,
    updateRowsPerPage,
    updateOrder,
    updateOrderBy,
  } = props
  const { t } = useTranslation()
  const [expandedClaim, setExpandedClaim] = useState<string | undefined>()
  const isDataLoading = isPending || isRefetching

  const handleRequestSort = useCallback(
    (
      _: React.MouseEvent<unknown>,
      property: keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail,
    ) => {
      const isAsc = orderBy === property && order === "asc"
      const nextValue = isAsc ? "desc" : "asc"
      updateOrder(nextValue)
      updateOrderBy(property)
      updatePage(0)
    },
    [order, orderBy],
  )

  const handleChangePage = useCallback(
    (_: unknown, newPage: number) => updatePage(newPage),
    [],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = parseInt(event.target.value, 10)
      updateRowsPerPage(nextValue)
      updatePage(0)
    },
    [],
  )

  const onRowClick = useCallback(
    (row: any) => {
      const rowId = row?.email?.id
      setExpandedClaim(
        rowId === expandedClaim || !!expandedClaim ? undefined : rowId,
      )
    },
    [expandedClaim],
  )

  const collapseRow = useCallback(() => {
    if (expandedClaim) {
      setExpandedClaim(undefined)
    }
  }, [expandedClaim])

  useEffect(() => {
    if (expandedClaim) {
      setTimeout(() => {
        const targetElement = document.getElementById(expandedClaim)
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "end" })
        }
      }, 400)
    }
  }, [expandedClaim])

  return (
    <Box display="grid" paddingBottom="24px">
      <ClickAwayListener onClickAway={() => setExpandedClaim(undefined)}>
        <EnhancedTableContainer>
          <EnhancedTable aria-label="collapsible table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onHeaderClick={collapseRow}
            />
            <TableBody>
              <EmptyRow onClick={collapseRow}>
                <TableCell colSpan={8} height="16px" />
              </EmptyRow>
              {isDataLoading ? (
                Array.from({ length: rowsPerPage }).map((_, index) => (
                  <React.Fragment key={index}>
                    <CustomRow open={false} className="disabled" key={index}>
                      <TableCell align="right">
                        <Skeleton
                          variant="rounded"
                          height="24px"
                          width="24px"
                          sx={{ ml: "8px" }}
                        />
                      </TableCell>
                      {Array.from({ length: 6 }).map((_, index) => (
                        <TableCell key={index}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            padding="8px 6px"
                          >
                            <Skeleton width="100%" />
                          </Box>
                        </TableCell>
                      ))}
                      <TableCell align="right">
                        <Skeleton
                          variant="rounded"
                          height="24px"
                          width="24px"
                          sx={{ ml: "auto", mr: "20px" }}
                        />
                      </TableCell>
                    </CustomRow>
                    <EmptyRow onClick={collapseRow}>
                      <TableCell colSpan={8} height="14px" />
                    </EmptyRow>
                  </React.Fragment>
                ))
              ) : !data?.items?.length ? (
                <NoDataRow>
                  <TableCell align="center" colSpan={15}>
                    <Typography>{t("noData")}</Typography>
                  </TableCell>
                </NoDataRow>
              ) : (
                data?.items.map((row) => {
                  return (
                    <React.Fragment key={row.email.id}>
                      <EnhancedTableRow
                        rowDetails={row.details}
                        emailDetails={row.email}
                        isDisabled={
                          !!expandedClaim && expandedClaim !== row?.email?.id
                        }
                        isExpanded={expandedClaim === row?.email?.id}
                        isEditingDisabled={disableEditing}
                        onRowClick={() => onRowClick(row)}
                        onRowClose={collapseRow}
                      />
                      <EmptyRow onClick={collapseRow} id={row.email.id}>
                        <TableCell colSpan={8} height="12px" />
                      </EmptyRow>
                    </React.Fragment>
                  )
                })
              )}
            </TableBody>
            <TableFooter onClick={collapseRow}>
              <TableRow>
                <TablePagination
                  count={data?.totalItems ?? 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[15, 30, 50, 100]}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rowsPerPage")}
                />
              </TableRow>
            </TableFooter>
          </EnhancedTable>
        </EnhancedTableContainer>
      </ClickAwayListener>
    </Box>
  )
}
