import { type Node } from "reactflow"

export const updateNestedConfig = (inputs: any, key: string, input: string) => {
  const keys = key.split(".")
  const obj = { ...inputs }
  let current = obj

  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      current[keys[i]] = {}
    }
    current = current[keys[i]]
  }

  current[keys[keys.length - 1]] = input
  return obj
}

export const getStepData = (nodes: Node[], nodeId: string) => {
  for (let i = nodes.length - 1; i >= 0; i--) {
    if (nodes[i]?.data.action === nodeId) {
      return nodes[i]?.data
    }
  }
}
