import {
  Grid,
  TableCell,
  TableHead,
  TableSortLabel,
  useMediaQuery,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { CustomRow } from "./styled"

interface HeadCell {
  id: string
  label: string
  width: string
  subFields?: any
  align?: any
}

interface IProps {
  sort: string
  order: "asc" | "desc"
  onSort: (column: string) => void
}

export const EnhancedTableHead = (props: IProps) => {
  const { sort, order, onSort } = props
  const { t } = useTranslation()
  const isGreaterThan1420 = useMediaQuery("(min-width: 1420px)")

  const headCells: readonly HeadCell[] = [
    {
      id: "Id",
      label: t("name"),
      width: "30%",
    },
    {
      id: "Version",
      label: t("version"),
      width: "10%",
    },
    {
      id: "created",
      label: "",
      width: "15%",
      subFields: [
        { id: "CreatedBy", label: t("createdBy") },
        {
          id: "CreatedAt",
          label: t("createdDate"),
        },
      ],
    },
    {
      id: "updated",
      label: "",
      width: "15%",
      subFields: [
        { id: "UpdatedBy", label: t("updatedBy") },
        {
          id: "UpdatedAt",
          label: t("updatedDate"),
        },
      ],
    },
    {
      id: "Description",
      label: t("description"),
      width: "20%",
    },
  ]
  return (
    <TableHead>
      <CustomRow>
        {headCells?.map((headCell) => {
          if (headCell.subFields) {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding="none"
                width={headCell.width}
                className={isGreaterThan1420 ? "word-break-all" : ""}
                style={{
                  minWidth: isGreaterThan1420 ? headCell.width : "200px",
                }}
              >
                <Grid container direction="column">
                  {headCell.subFields.map((subField: any) => (
                    <Grid item key={subField.id}>
                      <TableSortLabel
                        active={sort === subField.id}
                        direction={sort === subField.id ? order : "asc"}
                        onClick={() => onSort(subField.id)}
                      >
                        {subField.label}
                      </TableSortLabel>
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            )
          } else {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                style={{
                  minWidth: isGreaterThan1420 ? headCell.width : "200px",
                  textAlign: headCell.align,
                }}
                padding="none"
                sortDirection={sort === headCell.id ? order : false}
                width={headCell.width}
                className={isGreaterThan1420 ? "word-break-all" : ""}
              >
                <TableSortLabel
                  active={sort === headCell.id}
                  direction={sort === headCell.id ? order : "asc"}
                  onClick={() => onSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            )
          }
        })}
        <TableCell width="10%" />
      </CustomRow>
    </TableHead>
  )
}
