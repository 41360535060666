import {
    Box,
    CircularProgress,
    DialogContent,
    DialogTitle,
    IconButton,
  } from "@mui/material"
  import { useTranslation } from "react-i18next"
  import DocViewer, {
    DocViewerRenderers,
    type IDocument,
  } from "@cyntler/react-doc-viewer"
  import { useMemo } from "react"
  import { Close } from "@mui/icons-material"
import { ContentWrapper, CustomDialog } from "../InterractionCard/components/InteractionModal/styled"
  
  interface IProps {
    open: boolean
    data: any
    onClose: () => void
  }
  
  export const DocumentModal = (props: IProps) => {
    const { open, data, onClose } = props
    const { t } = useTranslation()
  
    const documents: IDocument[] = useMemo(() => {
      if (data?.url !== "") {
        const modifiedUrl = data.url
        const fileName = modifiedUrl.substring(modifiedUrl.lastIndexOf("/") + 1);
        return [
          {
            fileName: fileName || "file",
            fileType: "docx",
            uri: modifiedUrl,
          },
        ]
      }
      return []
    }, [data])
  
    return (
      <CustomDialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {t("stepInteraction")}
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          { data?.url ? (
            <ContentWrapper>
                <DocViewer
                  documents={documents}
                  pluginRenderers={DocViewerRenderers}
                  config={{
                    header: {
                      disableHeader: true,
                    },
                    pdfVerticalScrollByDefault: true,
                  }}
                />
            </ContentWrapper>
          ) : (
            <Box>{t("noData")}</Box>
          )}
        </DialogContent>
      </CustomDialog>
    )
  }
  