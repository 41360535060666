import React, { useState, useCallback, useMemo } from "react"
import {
  Box,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"

import {
  getOrganizationsManagementAPI,
  deleteOrganizationAPI,
} from "../../services"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"

import { AlertDialog } from "../../components/AlertDialog"

import { EditOrganizationModal } from "../../components/EditOrganizationModal"
import { LoadingButton } from "@mui/lab"
import { Add } from "@mui/icons-material"
import CreateOrganizationModal from "../../components/CreateOrganizationModal/CreateOrganizationModal"
import { useNavigate } from "react-router-dom"
import { useToast } from "../../contexts"
import dayjs from "dayjs"

export const OrganizationManagementPage: React.FC = () => {
  const { t } = useTranslation()

  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [editModalOpen, setEditModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedOrganizationData, setSelectedOrganizationData] = useState<
    Partial<IOrganizationManagement>
  >({})
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [organizationIdToDelete, setOrganizationIdToDelete] = useState<
    string | null
  >(null)
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")

  const toast = useToast()
  const navigate = useNavigate()

  const handleRowClick = (organizationId: string) => {
    navigate(`/group-management?organizationId=${organizationId}`)
  }

  const {
    data: organizations,
    isRefetching,
    isLoading,
  } = useQuery<IOrganizationManagement[]>({
    queryKey: ["organizations-management"],
    queryFn: () => getOrganizationsManagementAPI(),
    refetchOnMount: true,
  })

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    [],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [],
  )

  const sortedOrganizations = useMemo(() => {
    if (!organizations) return []

    return [...organizations].sort((a, b) => {
      const orderMultiplier = sortOrder === "asc" ? 1 : -1

      if (sortBy === "name") {
        return a.name.localeCompare(b.name) * orderMultiplier
      } else if (sortBy === "organizationType") {
        return (
          a.organizationType?.localeCompare(b.organizationType) *
          orderMultiplier
        )
      } else if (sortBy === "createdDate") {
        return (
          (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) *
          orderMultiplier
        )
      }

      return 0
    })
  }, [organizations, sortBy, sortOrder])

  const visibleRows = useMemo(
    () =>
      sortedOrganizations.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [sortedOrganizations, page, rowsPerPage],
  )
  const { mutate: deleteOrganization, isPending: isDeleting } = useMutation({
    mutationFn: (groupId: string) => deleteOrganizationAPI(groupId),
    onSuccess: () => {
      void queryClient.refetchQueries({
        queryKey: ["organizations-management"],
      })
      toast.show(t("organizationDeleted"), "success")
      setDeleteDialogOpen(false)
    },
    onError: () => {
      setDeleteDialogOpen(false)
    },
  })

  const handleSort = (column: string) => {
    setSortBy(column)
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }

  const totalOrganizationsCount = useMemo(
    () => organizations?.length || 0,
    [organizations],
  )

  const handleUpdateModalOpen = (organization: any) => {
    setSelectedOrganizationData(organization)
    setEditModalOpen(true)
  }

  const handleDeleteClick = (organizationId: string) => {
    setOrganizationIdToDelete(organizationId)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    if (organizationIdToDelete) {
      deleteOrganization(organizationIdToDelete)
      setOrganizationIdToDelete(null)
    }
  }

  const handleCancelDelete = () => {
    setOrganizationIdToDelete(null)
    setDeleteDialogOpen(false)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      padding="24px"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        <Box
          display="flex"
          gap={isSmallerThanLg ? "8px" : "16px"}
          flexDirection={isSmallerThanLg ? "column" : "row"}
          alignItems={isSmallerThanLg ? "" : "center"}
          marginBottom="24px"
        >
          <Typography flex={1} variant="h4" paddingRight="16px">
            {t("organizationManagement")}
          </Typography>

          <LoadingButton
            onClick={() => setCreateModalOpen(true)}
            startIcon={<Add />}
          >
            {t("createOrganization")}
          </LoadingButton>
        </Box>
        <Box display="grid">
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="30%">
                    <TableSortLabel
                      active={sortBy === "name"}
                      direction={sortOrder}
                      onClick={() => handleSort("name")}
                    >
                      {t("organizationName")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="30%">
                    <TableSortLabel
                      active={sortBy === "organizationType"}
                      direction={sortOrder}
                      onClick={() => handleSort("organizationType")}
                    >
                      {t("organizationType")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="30%">
                    <TableSortLabel
                      active={sortBy === "createdDate"}
                      direction={sortOrder}
                      onClick={() => handleSort("createdDate")}
                    >
                      {t("createdDate")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="5%"></TableCell>
                  <TableCell width="5%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading || isRefetching ? (
                  <>
                    {[...Array(rowsPerPage)].map((_, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {[...Array(5)].map((_, cellIndex) => (
                          <TableCell key={cellIndex}>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  visibleRows?.map((organization) => (
                    <TableRow key={organization.id}>
                      <TableCell
                        onClick={() => handleRowClick(organization.id)}
                        className="pointer"
                      >
                        {organization.name}
                      </TableCell>
                      <TableCell>
                        {organization.organizationType
                          ? t(organization.organizationType.toLocaleLowerCase())
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {organization.createdAt
                          ? dayjs(organization.createdAt).format(
                              "DD.MM.YYYY HH:mm",
                            )
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={t("editOrganization")}>
                          <IconButton
                            onClick={() => handleUpdateModalOpen(organization)}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={t("deleteOrganization")}>
                          <IconButton
                            onClick={() => handleDeleteClick(organization.id)}
                          >
                            <DeleteOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>

              <TableFooter>
                <TableRow>
                  {isLoading || isRefetching ? (
                    <TableCell colSpan={4}>
                      <Skeleton />
                    </TableCell>
                  ) : (
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      count={totalOrganizationsCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t("rowsPerPage")}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <CreateOrganizationModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />

      <EditOrganizationModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        organizationData={selectedOrganizationData}
      />

      <AlertDialog
        isVisible={deleteDialogOpen}
        message={t("areYouSureYouWantToDeleteOrganization")}
        confirmLabel={t("delete")}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        loading={isDeleting}
      />
    </Box>
  )
}
