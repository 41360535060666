import { type Dispatch, type SetStateAction } from "react"
import ReactFlow, {
  Background,
  Controls,
  type Edge,
  MiniMap,
  type Node,
  type OnNodesChange,
  type OnEdgesChange,
} from "reactflow"
import "./index.css"
import { ActionCard, BooleanCard, InteractionCard } from "./components"

interface IProps {
  nodeOptions: {
    nodes: Node[]
    onNodesChange: OnNodesChange
    setNodes: Dispatch<SetStateAction<Array<Node<any, string | undefined>>>>
  }
  edgeOptions: {
    edges: Edge[]
    onEdgesChange: OnEdgesChange
    setEdges: Dispatch<SetStateAction<Array<Edge<any>>>>
  }
}

const nodeTypes = {
  actionCard: ActionCard,
  boolCard: BooleanCard,
  interactionCard: InteractionCard,
}

export const CanvasMap = (props: IProps) => {
  const {
    nodeOptions: { nodes, onNodesChange },
    edgeOptions: { edges, onEdgesChange },
  } = props

  const isPreviewFromWorkflowListing =
    window.location.pathname.includes("workflow-overview")

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      nodesDraggable={false}
      onlyRenderVisibleElements
      defaultViewport={{
        zoom: 1,
        x: 0,
        y: isPreviewFromWorkflowListing ? 125 : 400,
      }}
    >
      <MiniMap pannable zoomable />
      <Controls showInteractive={false} />
      <Background />
    </ReactFlow>
  )
}
