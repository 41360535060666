import { Delete } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { deleteGlobalInboxAttachmentAPI } from "../../../../../../../../../../../../services"
import { useToast } from "../../../../../../../../../../../../contexts"
import { useCallback } from "react"

interface IProps {
  isOpen: boolean
  emailId: string
  documentUrl: string
  onClose: () => void
}

export const DeleteAttachmentModal = (props: IProps) => {
  const { isOpen, emailId, documentUrl, onClose } = props
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate, isPending } = useMutation({
    mutationFn: deleteGlobalInboxAttachmentAPI,
    onSuccess: () => {
      onClose()

      // Update the global inbox and global inbox attachments queries
      queryClient.setQueryData<IGetGlobalInboxResponse>(
        ["global-inbox"],
        (prev) => {
          if (!prev) return
          return {
            ...prev,
            items: prev?.items.map((i) => {
              if (i.email.id === emailId) {
                return {
                  ...i,
                  details: {
                    ...i.details,
                    attachmentCount: i.details.attachmentCount - 1,
                  },
                }
              }
              return i
            }),
          }
        },
      )
      queryClient.setQueryData<IGetGlobalInboxAttachmentsResponse>(
        ["global-inbox-attachments"],
        (prev) => {
          if (!prev) return
          return {
            inboxAttachments: prev?.inboxAttachments.filter(
              (i) => i.url !== documentUrl,
            ),
          }
        },
      )

      toast.show(t("attachmentDeletedSuccessfully"), "success")
    },
  })

  const handleDeleteDocument = useCallback(() => {
    mutate({ url: documentUrl })
  }, [documentUrl])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("deleteAttachment")}</DialogTitle>
      <DialogContent>{t("confirmDeleteAttachment")}</DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          size="small"
          color="error"
          loading={isPending}
          startIcon={<Delete />}
          onClick={handleDeleteDocument}
        >
          {t("delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
