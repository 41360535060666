import { Add } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
  type EdgeProps,
} from "reactflow"
import { ButtonContainer } from "./styled"
import { colors } from "../../../../../../utils"
import { useCallback } from "react"
import { generateId } from "../../../../utils"

export const AddButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  source: _,
  target,
}: EdgeProps) => {
  const { setNodes, setEdges, getNodes, getEdges } = useReactFlow()
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const onEdgeClick = useCallback(() => {
    const nodes = getNodes()
    const edges = getEdges()

    const nextNode = nodes.find((node) => node.id === target)
    const previousNodeId = edges.find((edge) => edge.target === nextNode?.id)
      ?.source
    if (!nextNode || !previousNodeId) return

    const newNodeId = generateId("step", "D", "DZ")

    const newNodes = {
      id: newNodeId,
      type: "dropzoneCard",
      data: {
        id: newNodeId,
        title: "step",
      },
      position: { x: 0, y: 0 },
    }

    const newEdges = [
      {
        id: generateId("edge", "BR", "", previousNodeId, newNodeId),
        source: previousNodeId,
        target: newNodeId,
        type: "",
        style: { stroke: colors.gray3 },
      },
      {
        id: generateId("edge", "BR", "", newNodeId, nextNode.id),
        source: newNodeId,
        target: nextNode.id,
        type: "",
        style: { stroke: colors.gray3 },
      },
    ]

    setNodes([...nodes, newNodes])
    setEdges([...edges.filter((e) => e.id !== id), ...newEdges])
  }, [])

  return (
    <>
      <BaseEdge
        path={edgePath}
        style={{ stroke: colors.gray3 }}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <ButtonContainer labelX={labelX} labelY={labelY}>
          <IconButton size="small" color="primary" onClick={onEdgeClick}>
            <Add />
          </IconButton>
        </ButtonContainer>
      </EdgeLabelRenderer>
    </>
  )
}
