import { axiosWorkflow } from "../lib"

export const getChannelConfigurationsAPI = (
  channelType: TChannelTypeName,
): Promise<IChannelConfiguration[]> =>
  axiosWorkflow.get("/Listener/channelConfiguration?channelType=" + channelType)

export const getAllChannelConfigurationsAPI = (): Promise<
  IChannelConfiguration[]
> => axiosWorkflow.get("/Listener/channelConfiguration")

export const getScopeDefinion = (): Promise<TScopeDefinition> =>
  axiosWorkflow.get("/Listener/scopeDefinition")
