import {
    Box,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
  } from "@mui/material";
  import { useTranslation } from "react-i18next";
  import { Close } from "@mui/icons-material";
  import {
    ContentWrapper,
    CustomDialog,
  } from "../InterractionCard/components/InteractionModal/styled";
  
  interface IProps {
    open: boolean;
    data: any;
    onClose: () => void;
  }
  
  export const LogsModal = (props: IProps) => {
    const { open, data, onClose } = props;
    const { t } = useTranslation();
  
    return (
      <CustomDialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {t("logHistory")}
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {data.executionLogs && data.executionLogs.length > 0 ? (
            <ContentWrapper>
              <TableContainer component={Paper} sx={{ width: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box sx={{fontWeight: "bold"}}>
                          {t("time")}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{fontWeight: "bold"}}>
                          {t("logLevel")}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{fontWeight: "bold"}}>
                          {t("message")}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {data.executionLogs.map((log: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{log.Time}</TableCell>
                      <TableCell>
                        <Box
                          component="span"
                          sx={{
                            fontWeight: "bold",
                            color:
                              log.LogLevel === "Error" ? "red" : "gray",
                          }}
                        >
                          {log.LogLevel}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ width: "100%" }}>
                        <Box
                          component="span"
                          dangerouslySetInnerHTML={{
                            __html: log.Message.replace(/\n/g, "<br />"),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                </Table>
              </TableContainer>
            </ContentWrapper>
          ) : (
            <Box sx={{width:"100%", height:"100%", textAlign:"center", alignContent:"center"}}>{t("noLogs")}</Box>
          )}
        </DialogContent>
      </CustomDialog>
    );
  };
  