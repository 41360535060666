import { useTranslation } from "react-i18next"
import { type IFilters } from "../../utils"
import { CustomStack, Item } from "./styled"
import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { WorkflowStatusChip } from "../../../../components"

interface IProps {
  filters: IFilters
}

export const ActiveFiltersBar = (props: IProps) => {
  const { filters } = props
  const { t } = useTranslation()

  return (
    <CustomStack
      direction="row"
      spacing={4}
      rowGap={2}
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      maxWidth="100%"
    >
      {!!filters?.name && (
        <Item>
          <Typography variant="regularSemiBold">{t("name")}: </Typography>
          <Typography>{filters.name}</Typography>
        </Item>
      )}
      {!!filters.caseNumber && (
        <Item>
          <Typography variant="regularSemiBold">{t("caseNumber")}: </Typography>
          <Typography>{filters.caseNumber}</Typography>
        </Item>
      )}
      {!!filters?.createdBy && (
        <Item>
          <Typography variant="regularSemiBold">{t("createdBy")}: </Typography>
          <Typography>{filters.createdBy}</Typography>
        </Item>
      )}
      {(!!filters?.createdStartDate || !!filters?.createdEndDate) && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("createdDate")}:{" "}
          </Typography>
          <Typography>
            {filters.createdStartDate
              ? dayjs(filters.createdStartDate).format("DD.MM.YYYY")
              : "♾️"}{" "}
            -{" "}
            {filters.createdEndDate
              ? dayjs(filters.createdEndDate).format("DD.MM.YYYY")
              : "♾️"}
          </Typography>
        </Item>
      )}
      {filters?.status !== -1 && (
        <Item>
          <Typography className="normal-word-break" variant="regularSemiBold">
            {t("status")}:
          </Typography>
          <Box display="flex" flexWrap="wrap" gap="4px">
            <WorkflowStatusChip status={filters.status} />
          </Box>
        </Item>
      )}
    </CustomStack>
  )
}
