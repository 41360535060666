import { useTranslation } from "react-i18next"
import { type Node, useEdgesState, useNodesState } from "reactflow"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  type MutableRefObject,
} from "react"
import {
  DndContext,
  rectIntersection,
  type DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Tabs,
  TextField,
  Typography,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { ArrowBackIosNew, Search } from "@mui/icons-material"

import { useToast } from "../../contexts"
import {
  transformStepToNode,
  transformNodeToStep,
  transformDraggedItem,
  createNewIds,
  addRegularNode,
  addBranchedNode,
  createEdges,
  generateId,
} from "./utils"
import {
  getWorkflowDefinitionAPI,
  postWorkflowDefinitionAPI,
  updateWorkflowDefinitionAPI,
  hasWorkflowInstances,
} from "../../services"
import {
  ActionConfigPanel,
  CanvasMap,
  CreateDefinitionModal,
  CustomActionsTabPanel,
  DiscardModal,
  PropertiesTab,
  VersionConfirmationModal,
  WorkflowCards,
} from "./components"
import {
  Wrapper,
  Header,
  InformationBox,
  TabsWrapper,
  CustomTab,
  ButtonsBox,
  SideColumn,
  SideColumnContentBox,
} from "./styled"

export const WorkflowConfigurator = () => {
  const { t } = useTranslation()
  const { id, version } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const toast = useToast()
  const queryClient = useQueryClient()

  const iterator = useRef<number>(0)
  const initialNodesRef = useRef<Node[] | null>(null)
  const isNewDefinition = pathname.endsWith(
    "/workflow-configurator/newDefinition/0",
  )
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [dummyFields, setDummyFields] = useState<Record<string, any>>({})
  const [selections, setSelections] = useState<Record<string, boolean>>({
    "Channel Type": false,
    Channels: false,
    "Document Type": false,
    Lawyer: false,
    Referate: false,
  })
  const [selectAllItems, setSelectAllItems] = useState<Record<string, boolean>>(
    {
      channelType: false,
      channels: false,
      docTypes: false,
      referate: false,
      lawyer: false,
    },
  )

  const [isCreateDefinitionModalOpen, setIsCreateDefinitionModalOpen] =
    useState(false)
  const [selectedActionId, setSelectedActionId] = useState<string | undefined>()

  const [ids, setIds] = useState<Record<string, string>>({
    ChannelId: "''",
    ApiSetupId: "''",
    ApiSetupDetailsId: "''",
  })
  const [activeTab, setActiveTab] = useState<"builder" | "properties">(
    "builder",
  )

  const [activeMethodsTab, setActiveMethodsTab] = useState<
    "triggers" | "actions" | "core"
  >("triggers")

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { data, isPending, isRefetching, refetch } = useQuery({
    queryKey: ["workflow-definition"],
    queryFn: () =>
      getWorkflowDefinitionAPI(id!, { version: version! }).then(
        (res: IWorkflowDefinitionRes) => {
          setModifiedData(res)
          return res
        },
      ),
    enabled: !!id && !!version && !isNewDefinition,
    refetchOnMount: true,
  })

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const [modifiedData, setModifiedData] = useState<IWorkflowDefinitionRes>(
    data as IWorkflowDefinitionRes,
  )

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false)

  const { mutate, isPending: isCreating } = useMutation<
    IWorkflowDefinitionRes,
    Error,
    IWorkflowDefinition
  >({
    mutationFn: async (body: IWorkflowDefinition) => {
      let hasInstances
      if (modifiedData) {
        hasInstances = await hasWorkflowInstances(
          modifiedData.id,
          modifiedData.version,
        )
      }
      if (
        isNewDefinition ||
        (modifiedData.active && hasInstances) ||
        modifiedData === undefined
      ) {
        return postWorkflowDefinitionAPI(body)
      } else {
        return updateWorkflowDefinitionAPI(body)
      }
    },
    onSuccess: () => {
      toast.show(t("New workflow definition is created!"), "success")
      navigate("/workflow-definitions")
    },
  })

  const sensors = useSensors(useSensor(PointerSensor))

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: "builder") => {
      setActiveTab(newValue)
    },
    [],
  )

  const handleCardTabChange = useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: "triggers" | "actions" | "core",
    ) => {
      setActiveMethodsTab(newValue)
    },
    [],
  )

  const updateNodesAndEdges = (
    over: any,
    transformedItem: Partial<IMethodItem>,
    newId: string,
  ) => {
    if (!over) return

    if (transformedItem.action === "If") {
      const { transformedNodes, transformedEdges } = addBranchedNode(
        nodes,
        edges,
        transformedItem,
        newId,
        over,
        2,
      )

      setNodes(transformedNodes)
      setEdges(transformedEdges)
    } else {
      const { transformedNodes, transformedEdges } = addRegularNode(
        nodes,
        edges,
        transformedItem,
        newId,
        over,
      )

      setNodes(transformedNodes)
      setEdges(transformedEdges)
    }
  }

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const transformedItem = transformDraggedItem(active)
      const newId = createNewIds(over, transformedItem)

      // If the first node is not a trigger node, raise error
      if (over?.id?.toString().includes("$D/DZ")) {
        if (
          nodes?.find((node) => node?.id === over?.id)?.data?.title ===
          "trigger"
        ) {
          if (transformedItem.type !== "trigger") {
            toast.show(t("mustAddTrigger"), "error")
            return
          }
        }
      }

      updateNodesAndEdges(over, transformedItem, newId)
    },
    [nodes, edges, setNodes, setEdges],
  )

  const handleCardClick = useCallback(
    (item: INodeItem | null) => setSelectedActionId(item?.id),
    [],
  )

  const handleUpdateAction = (
    action: INodeItem,
    type: "input" | "output" | "selectNext" | "do",
  ) => {
    if (type === "input") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  inputs: action?.inputs,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "output") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  outputs: action?.outputs,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "selectNext") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  selectNextStep: action?.selectNextStep,
                },
              }
            : node,
        )
        return newNodes
      })
    }
    if (type === "do") {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) =>
          node.id === action?.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  do: action?.do,
                },
              }
            : node,
        )
        return newNodes
      })
    }
  }

  const initialNodes = useMemo(() => {
    if (isNewDefinition) {
      const generatedId = generateId("step", "D", "DZ")
      return [
        {
          id: generatedId,
          type: "dropzoneCard",
          data: {
            id: generatedId,
            title: "trigger",
          },
          position: { x: 0, y: 0 },
        },
      ]
    }

    const nodes: Node[] = []

    data?.steps?.forEach((step) => {
      const parentId = data?.steps?.find((s) => {
        return (
          s.nextStepId === step.id || s.do?.[0]?.[0]?.id === step.nextStepId
        )
      })?.id
      nodes.push(
        ...transformStepToNode(step, parentId === undefined ? "" : parentId),
      )
    })

    return nodes
  }, [data?.steps])

  const initialEdges = useMemo(() => {
    if (isNewDefinition) return []

    return createEdges(initialNodes)
  }, [initialNodes])

  useEffect(() => {
    if (!isNewDefinition && !initialNodes) {
      void refetch()
    }

    return () => setNodes([])
  }, [initialNodes])

  useEffect(() => {
    if (data?.version !== version && !isNewDefinition) {
      void refetch()
    }
  }, [version])

  useEffect(() => {
    if (initialNodes && initialEdges) {
      setNodes(initialNodes)
      setEdges(initialEdges)
    }
  }, [initialEdges])

  useEffect(() => {
    queryClient.removeQueries({ queryKey: ["workflow-definition"] })
  }, [])

  const handleCreateNewDefinition = useCallback(
    (
      definitionDetails: { name: string; description: string },
      buttonClicked: string,
    ) => {
      if (nodes.length === 0) return

      const transformedSteps = transformNodeToStep(nodes, edges)
      const updatedSteps = updateInputsInSteps(transformedSteps)

      mutate({
        id: definitionDetails.name,
        description: definitionDetails?.description,
        name: definitionDetails?.name,
        createdAt: new Date(),
        createdBy: {
          id: user?.id.toString() ?? "",
          name: user?.firstName ?? "",
          surname: user?.lastName ?? "",
          email: user?.email ?? ""
        },
        updatedAt: null,
        updatedBy: null,
        deleted: false,
        active: buttonClicked !== "Create as draft",
        version: 1,
        HasWorkflowInstance: false,
        steps: updatedSteps!,
      })
    },
    [nodes, edges, data],
  )

  const updateInputsInSteps: (
    steps: IWorkflowStep[] | undefined,
  ) => IWorkflowStep[] | undefined = (steps) => {
    return steps?.map((step) => {
      if (
        step.stepType === "ClaimProperty" ||
        step.stepType === "IncomingEmail"
      ) {
        return {
          ...step,
          inputs: {
            ChannelType: step.inputs?.ChannelType
              ? step.inputs.ChannelType
              : [""],
            ChannelId: step.inputs?.ChannelId ? step.inputs.ChannelId : [""],
            DocType: step.inputs?.DocType ? step.inputs.DocType : [""],
            Lawyer: step.inputs?.Lawyer ? step.inputs.Lawyer : [""],
            Referate: step.inputs?.Referate ? step.inputs.Referate : [""],
          },
        }
      } else if (step.stepType === "UpdateDatabase") {
        return {
          ...step,
          inputs: Object.keys(step.inputs)?.reduce((acc: any, key: string) => {
            if (step.inputs[key].length !== 0) {
              acc[key] = step.inputs[key]
            }
            return acc
          }, {}),
        }
      } else if (step.stepType === "GenericTrigger") {
        const scope = step.inputs?.Scope
        return {
          ...step,
          inputs: {
            Scope: {
              ChannelType: scope?.ChannelType ? scope?.ChannelType : [""],
              ChannelId: scope?.ChannelId ? scope?.ChannelId : [""],
              DocType: scope?.DocType ? scope?.DocType : [""],
              Lawyer: scope?.Lawyer ? scope?.Lawyer : [""],
              Referate: scope?.Referate ? scope?.Referate : [""],
            },
          },
        }
      } else if (step.stepType === "TemplateBuilderStep") {
        return {
          ...step,
          inputs: {
            ...step.inputs,
            TemplateFileUrl: step?.inputs?.TemplateFileUrl
              ? `'${step.inputs?.TemplateFileUrl.replaceAll("'", "")}'`
              : "''",
          },
        }
      } else if (step.stepType === "GetDataStep") {
        const i = step?.inputs
        return {
          ...step,
          inputs: {
            ChannelId: step?.inputs?.ChannelId || ids.ChannelId,
            ApiSetupId: step?.inputs?.ApiSetupId || ids.ApiSetupId,
            SelectedFields: i?.SelectedFields ? i?.SelectedFields : {},
          },
        }
      } else {
        return step
      }
    })
  }

  const handleUpdateDefinition = useCallback(async () => {
    const hasInstances = await hasWorkflowInstances(data?.id!, data?.version!)
    if (nodes.length === 0) return

    const transformedSteps = transformNodeToStep(nodes, edges)
    const updatedSteps = updateInputsInSteps(transformedSteps)

    const updatedData = {
      ...modifiedData,
      steps: updatedSteps ?? [],
      updatedAt: new Date(),
      updatedBy: {
        id: user?.id.toString() ?? "",
        name: user?.firstName ?? "",
        surname: user?.lastName ?? "",
        email: user?.email ?? ""
      }
    }
    setModifiedData(updatedData)
  
    if (!modifiedData?.active || (modifiedData?.active && !hasInstances)) {
      mutate(updatedData)
    } else {
      setIsConfirmationModalOpen(true)
    }
  }, [nodes, edges, data, modifiedData])

  const handleCreateOrUpdateClick = useCallback(async () => {
    isNewDefinition
      ? setIsCreateDefinitionModalOpen(true)
      : await handleUpdateDefinition()
  }, [isNewDefinition, handleUpdateDefinition])

  const isLoading = isPending || isRefetching

  useEffect(() => {
    iterator.current = iterator.current + 1
    if (iterator.current === 4 || iterator.current === 5) {
      initialNodesRef.current = nodes
    }

    window.history.pushState({}, "", window.location.pathname)

    const handlePopState = (event: PopStateEvent) => {
      if (newChangesExist(initialNodesRef)) {
        event.preventDefault()

        const confirmNavigation = window.confirm(
          "All unsaved changes will be lost! Are you sure you want to continue?",
        )

        if (!confirmNavigation) {
          window.history.pushState({}, "", window.location.pathname)
        } else {
          navigate("/workflow-definitions")
        }
      } else {
        navigate("/workflow-definitions")
      }
    }

    const handleBeforeUnload: (event: BeforeUnloadEvent) => void = (event) => {
      if (newChangesExist(initialNodesRef)) {
        event.preventDefault()

        const confirmNavigation = window.confirm(
          "All unsaved changes will be lost! Are you sure you want to continue?",
        )

        if (!confirmNavigation) {
          window.history.pushState({}, "", window.location.pathname)
        }
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [nodes])

  const newChangesExist: (
    refNodes: MutableRefObject<Node[] | null>,
  ) => boolean | undefined = (refNodes) => {
    if (refNodes.current?.length !== nodes.length) {
      return true
    } else {
      const equalNodes = nodes.map(
        (node, index) => node.id === refNodes.current![index]?.id,
      )

      return equalNodes.includes(false)
    }
  }

  const handleCancelClick = () => {
    if (
      isNewDefinition ||
      (!isNewDefinition && newChangesExist(initialNodesRef))
    ) {
      setIsOpen(true)
    } else {
      initialNodesRef.current = null
      navigate("/workflow-definitions")
    }
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      collisionDetection={rectIntersection}
    >
      <Wrapper>
        <Header>
          <InformationBox>
            <IconButton size="small" onClick={handleCancelClick}>
              <ArrowBackIosNew fontSize="inherit" />
            </IconButton>
            {isNewDefinition ? (
              <Typography
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {t("newDefinition")}
              </Typography>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="regularMedium"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {data?.id}
                </Typography>
                <Typography
                  variant="extraSmall"
                  color="gray"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {data?.description}
                </Typography>
              </Box>
            )}
          </InformationBox>
          <TabsWrapper>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <CustomTab label={t("builder")} value="builder" />
              {!isNewDefinition && (
                <CustomTab label={t("properties")} value="properties" />
              )}
            </Tabs>
          </TabsWrapper>
          <ButtonsBox>
            <Button size="small" variant="outlined" onClick={handleCancelClick}>
              {t("cancel")}
            </Button>
            <LoadingButton
              loading={isCreating}
              size="small"
              onClick={handleCreateOrUpdateClick}
            >
              {isNewDefinition ? t("create") : t("update")}
            </LoadingButton>
          </ButtonsBox>
        </Header>
        {activeTab === "builder" && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            height="100%"
          >
            <SideColumn overflow="scroll" paddingBottom="30px">
              <SideColumnContentBox>
                <Typography
                  variant="largeMedium"
                  alignSelf="flex-start"
                  marginTop="32px"
                >
                  {t("workflowBuilder")}
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("search")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={() => {}}
                />
              </SideColumnContentBox>
              <Box height="16px" />
              <Tabs value={activeMethodsTab} onChange={handleCardTabChange}>
                <CustomTab label={t("triggers")} value="triggers" />
                <CustomTab label={t("actions")} value="actions" />
                <CustomTab label={t("core")} value="core" />
              </Tabs>
              <Divider flexItem />
              <SideColumnContentBox>
                <CustomActionsTabPanel
                  value="triggers"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
                <CustomActionsTabPanel
                  value="actions"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
                <CustomActionsTabPanel
                  value="core"
                  activeTab={activeMethodsTab}
                >
                  <WorkflowCards category={activeMethodsTab} searchKey={null} />
                </CustomActionsTabPanel>
              </SideColumnContentBox>
            </SideColumn>
            {isLoading && data ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <CanvasMap
                selectedActionId={selectedActionId}
                nodeOptions={{ nodes, onNodesChange, setNodes }}
                edgeOptions={{ edges, onEdgesChange, setEdges }}
                onCardSelect={handleCardClick}
              >
                {selectedActionId && (
                  <ActionConfigPanel
                    nodes={nodes}
                    edges={edges}
                    selectedActionId={selectedActionId}
                    onClosePanel={() => handleCardClick(null)}
                    onUpdateAction={handleUpdateAction}
                    setIds={setIds}
                    ids={ids}
                    selections={selections}
                    setSelections={setSelections}
                    dummyFields={dummyFields}
                    setDummyFields={setDummyFields}
                    selectAll={selectAllItems}
                    setSelectAll={setSelectAllItems}
                  />
                )}
              </CanvasMap>
            )}
          </Box>
        )}
        {activeTab === "properties" && (
          <PropertiesTab
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
          />
        )}
      </Wrapper>
      <CreateDefinitionModal
        isOpen={isCreateDefinitionModalOpen}
        onClickSubmit={handleCreateNewDefinition}
        onClose={() => setIsCreateDefinitionModalOpen(false)}
      />
      <VersionConfirmationModal
        open={isConfirmationModalOpen}
        modifiedData={modifiedData}
        isSubmitting={isCreating}
        onClose={() => setIsConfirmationModalOpen(false)}
        mutate={mutate}
      />
      <DiscardModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        onConfirm={() => {
          initialNodesRef.current = null
          setIsOpen(false)
          navigate("/workflow-definitions")
        }}
      />
    </DndContext>
  )
}
