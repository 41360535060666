import { Box } from "@mui/material"
import { BlacklistedPanel, GlobalPanel, TrashPanel } from "./components"

interface IProps {
  activeTab: string
}
export const TabPanel = (props: IProps) => {
  const { activeTab } = props

  return (
    <Box role="tabpanel" marginTop="12px">
      {activeTab === "global" && <GlobalPanel />}
      {activeTab === "blacklisted" && <BlacklistedPanel />}
      {activeTab === "trash" && <TrashPanel />}
    </Box>
  )
}
