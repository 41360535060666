import {
  AccountTree,
  CalendarMonth,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material"
import {
  Box,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getWorkflowsAPI } from "../../../../services"
import { SkeletonCard, SingleLineTypography, WorkflowCard } from "./styled"
import { colors } from "../../../../utils"
import { useNavigate } from "react-router-dom"
import { type IFilters } from "../../utils"
import { WorkflowStatusChip } from "../../../../components"

interface IProps {
  searchKey: string
  sort: string
  filters: IFilters
}

export const WorkflowsGrid = (props: IProps) => {
  const { searchKey, sort, filters } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()

  const [page, setPage] = useState(0)

  const displaySize = {
    isExtraLarge: useMediaQuery(theme.breakpoints.only("xl")),
    isLarge: useMediaQuery(theme.breakpoints.only("lg")),
    isMedium: useMediaQuery(theme.breakpoints.only("md")),
    isSmall: useMediaQuery(theme.breakpoints.only("sm")),
    isExtraSmall: useMediaQuery(theme.breakpoints.down("sm")),
  }

  const itemsPerPage = useMemo(() => {
    if (displaySize.isExtraLarge) return 16
    if (displaySize.isLarge) return 12
    if (displaySize.isMedium) return 8
    if (displaySize.isSmall || displaySize.isExtraSmall) return 6
    return 4
  }, [displaySize])

  const {
    data: workflows,
    isPending: isWorkflowsPending,
    isRefetching: isWorkflowsRefetching,
    refetch: refetchWorkflows,
  } = useQuery({
    queryKey: ["workflows-listing"],
    queryFn: () =>
      getWorkflowsAPI({
        page: page + 1,
        pageSize: itemsPerPage,
        createdFrom: filters.createdStartDate,
        createdTo: filters.createdEndDate,
        searchCaseNumber: filters.caseNumber,
        searchName: searchKey || filters.name,
        sortBy: "CreateTime",
        sortDesc: sort === "desc",
        statusFilter: filters.status !== -1 ? filters.status : null,
      }),
    enabled: false,
  })

  const isDataLoading = isWorkflowsPending || isWorkflowsRefetching

  const fromRowsNum = useMemo(
    () => page * itemsPerPage + 1,
    [page, itemsPerPage],
  )

  const toRowsNum = useMemo(() => {
    const potentialEnd = (page + 1) * itemsPerPage
    return Math.min(workflows?.totalItems ?? 0, potentialEnd)
  }, [workflows, page, itemsPerPage])

  const handleChangePage = useCallback((to: "next" | "prev") => {
    setPage((prev) => (to === "next" ? prev + 1 : prev - 1))
  }, [])

  useEffect(() => {
    if (searchKey) {
      const handler = setTimeout(() => {
        void refetchWorkflows()
      }, 500)

      return () => {
        clearTimeout(handler)
      }
    } else {
      void refetchWorkflows()
    }
  }, [searchKey, filters, page, itemsPerPage, sort])

  useEffect(() => {
    if (workflows && workflows.currentPage > workflows.totalPages) {
      setPage(0)
    }
  }, [workflows])

  return (
    <Grid container spacing={2} paddingX="24px">
      {isDataLoading ? (
        Array.from({ length: 8 }).map((_, index) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
            <SkeletonCard>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Skeleton width={150} />
                  <Skeleton variant="rounded" width={100} />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    marginBottom="12px"
                  >
                    <Skeleton width={200} />
                  </Box>
                  <Divider />
                  <Box
                    display="flex"
                    alignItems="start"
                    gap="8px"
                    marginTop="12px"
                  >
                    <Skeleton width={400} />
                  </Box>
                </Box>
              </CardContent>
            </SkeletonCard>
          </Grid>
        ))
      ) : workflows?.totalItems === 0 ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          marginTop="32px"
        >
          <Typography variant="large">{t("noData")}</Typography>
        </Box>
      ) : (
        workflows?.items?.map((workflow, index) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
            <WorkflowCard
              status={workflow.status}
              onClick={() =>
                navigate(
                  `/workflow-preview/${workflow.id}/${workflow.workflowDefinitionId}`,
                )
              }
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    gutterBottom
                    variant="largeSemiBold"
                    color={colors.black}
                  >
                    {workflow.workflowDefinitionId}
                  </Typography>
                  <WorkflowStatusChip status={workflow.status} />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    marginBottom="12px"
                  >
                    <Typography variant="regular" color={colors.gray3}>
                      {t("caseNumber")}:
                    </Typography>
                    <Typography fontWeight={600} color={colors.gray3}>
                      {workflow.data?.CaseNumber?.length > 0
                        ? workflow.data?.CaseNumber
                        : "--"}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    marginTop="12px"
                  >
                    <CalendarMonth fontSize="small" htmlColor={colors.gray3} />
                    <SingleLineTypography color={colors.gray3}>
                      {t("created")}:
                    </SingleLineTypography>
                    <Typography fontWeight={600} color={colors.gray3}>
                      {new Date(workflow.createTime).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <AccountTree
                className="card-icon"
                fontSize="small"
                htmlColor={colors.gray3}
              />
            </WorkflowCard>
          </Grid>
        ))
      )}
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="12px"
        >
          <Typography>
            {workflows?.totalItems === 0 ? 0 : fromRowsNum} - {toRowsNum}{" "}
            {t("of")} {workflows?.totalItems}
          </Typography>
          <IconButton
            size="small"
            disabled={page === 0}
            onClick={() => handleChangePage("prev")}
          >
            <NavigateBefore />
          </IconButton>
          <IconButton
            size="small"
            disabled={
              page * itemsPerPage + itemsPerPage >= (workflows?.totalItems ?? 0)
            }
            onClick={() => handleChangePage("next")}
          >
            <NavigateNext />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  )
}
