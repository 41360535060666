import { Check, Quiz } from "@mui/icons-material"
import {
  Box,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
} from "@mui/material"
import { ElevatedPopper, PopperOption } from "./styled"
import { useCallback, useRef, useState } from "react"
import { colors } from "../../../../../../../../../../../../utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postGlobalInboxAssignDocTypeAPI } from "../../../../../../../../../../../../services"
import { useToast } from "../../../../../../../../../../../../contexts"
import { useTranslation } from "react-i18next"

interface IProps {
  currentDoc: IGlobalInboxAttachment
  folderPath: string
  docTypes: IDocType[]
  isDocTypesLoading: boolean
}

export const AssignDocTypePopup = (props: IProps) => {
  const { currentDoc, folderPath, docTypes, isDocTypesLoading } = props

  const toast = useToast()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const anchorRef = useRef<HTMLDivElement>(null)

  const [openFilterPopup, setOpenFilterPopup] = useState(false)

  const { mutate: assignDocType, isPending: isAssignDocTypePending } =
    useMutation({
      mutationFn: (body: IGlobalInboxAssignDocTypeRequestBody) =>
        postGlobalInboxAssignDocTypeAPI(body),
      onSuccess: (_, variables) => {
        toast.show(t("docTypeSuccessfullyUpdated"), "success")
        queryClient.setQueryData<IGetGlobalInboxAttachmentsResponse>(
          ["global-inbox-attachments"],
          (prev) => {
            if (prev === undefined) return prev
            return {
              inboxAttachments: prev?.inboxAttachments?.map((attachment) => {
                if (attachment.url === variables.url) {
                  return {
                    ...attachment,
                    docType: variables.docType,
                  }
                }
                return attachment
              }),
            }
          },
        )
      },
    })

  const handleAssignDocType = useCallback(
    (docType: string) => {
      if (docType && currentDoc.docType !== docType && currentDoc.url) {
        assignDocType({
          docType,
          url: currentDoc.url,
          folderPath,
        })
      }
      setOpenFilterPopup(false)
    },
    [currentDoc, folderPath],
  )

  return (
    <>
      <ButtonGroup ref={anchorRef}>
        <IconButton
          disabled={isDocTypesLoading || isAssignDocTypePending}
          onClick={() => setOpenFilterPopup(true)}
          size="small"
        >
          {isAssignDocTypePending && currentDoc ? (
            <CircularProgress size="14px" />
          ) : (
            <Quiz fontSize="inherit" />
          )}
        </IconButton>
      </ButtonGroup>
      <ElevatedPopper
        open={openFilterPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            mouseEvent="onMouseUp"
            onClickAway={() => setOpenFilterPopup(false)}
          >
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "top right",
              }}
            >
              <Paper>
                <Box display="flex" flexDirection="column">
                  {docTypes.map((docType) => (
                    <PopperOption
                      key={docType.docType}
                      isSelected={docType.docType === currentDoc.docType}
                      onClick={() => handleAssignDocType(docType.docType)}
                    >
                      {docType.docType}
                      {docType.docType === currentDoc.docType && (
                        <Check htmlColor={colors.primary} fontSize="small" />
                      )}
                    </PopperOption>
                  ))}
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </ElevatedPopper>
    </>
  )
}
