import React from "react"
import { type FieldError } from "react-hook-form"
import { FullHeightAutocomplete } from "./styled"
import { ExpandMore } from "@mui/icons-material"
import { TextField, type TextFieldProps } from "@mui/material"
import { colors } from "../../../../../../utils"

interface FHAutoCompleteProps {
  model: Record<string, any>
  value: any
  placeholder?: string
  fieldKey: string
  handleChange?: (fieldKey: string, selectedValue: string) => void
  onChange: (...event: any[]) => void
  error: FieldError | undefined
}

export const FHAutoComplete: React.FC<FHAutoCompleteProps> = (props) => {
  const { model, value, placeholder, fieldKey, handleChange, onChange, error } =
    props

  return (
    <FullHeightAutocomplete
      options={Object.keys(model).filter((field) => model[field]?.selected)}
      value={value}
      freeSolo={true}
      popupIcon={<ExpandMore />}
      forcePopupIcon={true}
      onInputChange={(_e, data) => {
        handleChange?.(fieldKey, data)
        onChange(data || "")
      }}
      getOptionLabel={(option) => option as string}
      renderInput={(params) => (
        <TextField
          {...(params as TextFieldProps)}
          fullWidth
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          error={!!error?.message}
          helperText={error?.message}
          sx={{ width: "200px", backgroundColor: colors.white }}
        />
      )}
    />
  )
}
