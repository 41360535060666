import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import dayjs from "dayjs"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SingleLineTypography, StatusBox } from "../styled"
import { getExecutionStatusName } from "../utils"

interface IProps {
  executionPointers: IExecutionPointer[]
}

export const ExecutionLogsTable = (props: IProps) => {
  const { executionPointers } = props
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    [],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [],
  )

  const visibleRows = useMemo(
    () =>
      executionPointers?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [page, rowsPerPage],
  )

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("stepId")}</TableCell>
            <TableCell>{t("stepName")}</TableCell>
            <TableCell>{t("startTime")}</TableCell>
            <TableCell>{t("endTime")}</TableCell>
            <TableCell>{t("status")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={10}>{t("noData")}</TableCell>
            </TableRow>
          ) : (
            visibleRows?.map((row, index) => (
              <TableRow key={row?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.stepName}</TableCell>
                <TableCell>
                  {row.startTime
                    ? dayjs(row?.startTime).format("YYYY-MM-DD HH:mm:ss:SSS")
                    : "-"}
                </TableCell>
                <TableCell>
                  {row.endTime
                    ? dayjs(row?.endTime).format("YYYY-MM-DD HH:mm:ss:SSS")
                    : "-"}
                </TableCell>
                <TableCell>
                  {" "}
                  <StatusBox status={row?.status}>
                    <SingleLineTypography
                      variant="smallSemiBold"
                      color="inherit"
                    >
                      {t(getExecutionStatusName(row?.status))}
                    </SingleLineTypography>
                  </StatusBox>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={executionPointers?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("rowsPerPage")}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
