import { Delete } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { deleteGlobalInboxEmailAPI } from "../../../../../../../../../../services"
import { useToast } from "../../../../../../../../../../contexts"
import { useCallback } from "react"

interface IProps {
  isOpen: boolean
  id: string
  onClose: () => void
  onDelete: () => void
}

export const DeleteEmailModal = (props: IProps) => {
  const { isOpen, id, onClose, onDelete } = props
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate, isPending } = useMutation({
    mutationFn: deleteGlobalInboxEmailAPI,
    onSuccess: () => {
      void queryClient.refetchQueries({ queryKey: ["global-inbox"] })

      onDelete()
      toast.show(t("attachmentDeletedSuccessfully"), "success")
    },
  })

  const handleDeleteDocument = useCallback(() => {
    mutate(id)
  }, [id])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("deleteEmail")}</DialogTitle>
      <DialogContent>{t("confirmDeleteEmail")}</DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          size="small"
          color="error"
          loading={isPending}
          startIcon={<Delete />}
          onClick={handleDeleteDocument}
        >
          {t("delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
