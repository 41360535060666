import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"
import {
  ArrowDownward,
  ArrowUpward,
  Cached,
  Search,
  Sort,
  ViewComfy,
  TableRows,
} from "@mui/icons-material"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import {
  WorkflowsGrid,
  LaunchWorkflowModal,
  FilterPopup,
  ActiveFiltersBar,
} from "./components"
import { getFiltersCount } from "./utils"
import { SortButton } from "./styled"
import { useQuery } from "@tanstack/react-query"
import { WorkflowsTable } from "./components/WorkflowsTable/WorkflowsTable"

export const WorkflowListingPage = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down("lg"))
  const [searchKey, setSearchKey] = useState("")
  const [sort, setSort] = useState<"asc" | "desc">("desc")
  const [filters, setFilters] = useState({
    name: "",
    status: -1,
    caseNumber: "",
    createdBy: "",
    createdStartDate: "",
    createdEndDate: "",
  })
  const [view, setView] = useState<"grid" | "table">("table")
  const { isRefetching, refetch } = useQuery<IGetWorkflowsResponse>({
    queryKey: ["workflows-listing"],
    enabled: false,
  })

  const [isCreateWorkflowModalOpen, setIsCreateWorkflowModalOpen] =
    useState(false)

  const handleRefreshClick = useCallback(() => {
    void refetch()
  }, [])

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKey(event.target.value)
    },
    [],
  )

  const handleSort = useCallback(() => {
    setSort((prev) => (prev === "asc" ? "desc" : "asc"))
  }, [])

  const handleViewChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newView: "grid" | "table") => {
      if (newView !== null) {
        setView(newView)
      }
    },
    [],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
      >
        <Box
          display="flex"
          alignItems="center"
          marginBottom="12px"
          padding="0 24px"
          paddingTop="24px"
          gap="12px"
        >
          <Typography variant="h4" marginRight="auto">
            {t("automationOverview")}
          </Typography>
          <Tooltip title={t("refresh")} placement="left">
            <IconButton onClick={handleRefreshClick} disabled={isRefetching}>
              <Cached />
            </IconButton>
          </Tooltip>
          <Button onClick={() => setIsCreateWorkflowModalOpen(true)}>
            {t("launchWorkflow")}
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginBottom="12px"
          padding="0 24px"
          gap="12px"
        >
          <Typography
            variant="regular"
            color={colors.gray3}
            marginRight="auto"
            width={isSmallerThanLg ? "100%" : "55%"}
          >
            {t("workflowOverviewDescription")}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={isSmallerThanLg ? "100%" : "auto"}
            gap="8px"
          >
            <Box display="flex" alignItems="center" gap="8px">
              {view === "grid" && (
                <Tooltip
                  placement="left"
                  title={`${t("sort")}: ${
                    sort === "asc" ? t("ascending") : t("descending")
                  }`}
                >
                  <SortButton onClick={handleSort}>
                    {sort === "asc" ? <ArrowUpward /> : <ArrowDownward />}
                    <Sort />
                  </SortButton>
                </Tooltip>
              )}
              <FilterPopup filters={filters} onFilterChange={setFilters} />
            </Box>
            <TextField
              variant="outlined"
              placeholder={t("search")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="view toggle"
              size="small"
            >
              <ToggleButton value="table" aria-label="table view">
                <TableRows />
              </ToggleButton>
              <ToggleButton value="grid" aria-label="grid view">
                <ViewComfy />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        {getFiltersCount(filters) > 0 && <ActiveFiltersBar filters={filters} />}
        {view === "grid" && (
          <WorkflowsGrid searchKey={searchKey} sort={sort} filters={filters} />
        )}
        {view === "table" && (
          <WorkflowsTable searchKey={searchKey} filters={filters} />
        )}
      </Box>
      <LaunchWorkflowModal
        isOpen={isCreateWorkflowModalOpen}
        onClose={() => setIsCreateWorkflowModalOpen(false)}
      />
    </Box>
  )
}
