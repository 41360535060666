import React, { useCallback, useEffect } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import {
  type Control,
  Controller,
  type FieldValues,
  useForm,
} from "react-hook-form"
import { useToast } from "../../contexts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { PlaceController } from "../../pages/ClaimPage/components/FormField/components"
import { updateOrganizationAPI } from "../../services"
import { PhoneNumberInput } from "../PhoneNumberInput"
import { isValidPhoneNumber } from "react-phone-number-input"
import { EMAIL_REGEX_VALIDATION, NUMERIC_REGEX_VALIDATION } from "../../utils"

interface UpdateOrganizationModalProps {
  open: boolean
  onClose: () => void
  organizationData?: any
}

export const EditOrganizationModal: React.FC<UpdateOrganizationModalProps> = ({
  open,
  onClose,
  organizationData,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      name: organizationData?.name,
      organizationType: organizationData?.organizationType,
      email: organizationData?.email,
      phone: organizationData?.phone,
      houseNumber: organizationData?.houseNumber,
      address: organizationData.address || {
        postCodePlace: null,
        street: "",
      },
      externalDetails: organizationData.externalDetails,
    },
  })

  useEffect(() => {
    if (open && organizationData) {
      reset({
        name: organizationData.name,
        organizationType: organizationData?.organizationType,
        email: organizationData?.email,
        phone: organizationData?.phone,
        houseNumber: organizationData.houseNumber,
        address: organizationData.address || {
          postCodePlace: null,
          street: "",
        },
        externalDetails: organizationData.externalDetails,
      })
    }
  }, [open, organizationData, reset])

  const { mutate: updateOrganization, isPending: isUpdating } = useMutation({
    mutationFn: (data) =>
      updateOrganizationAPI(organizationData.id as string, data as any),
    onSuccess: () => {
      toast.show(t("organizationUpdated"), "success")
      void queryClient.refetchQueries({
        queryKey: ["organizations-management"],
      })
      onClose()
    },
  })
  const validatePhoneNumber = useCallback((value: string) => {
    if (!isValidPhoneNumber(value)) {
      return t("invalidPhoneNumberErrorMessage")
    } else {
      return true
    }
  }, [])
  const onFormSubmit = (data: any) => {
    updateOrganization(data)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("editOrganization")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px">
          <Controller
            name="name"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("name")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors.organizationType}
          >
            <InputLabel>{t("organizationType")}</InputLabel>
            <Controller
              name="organizationType"
              control={control}
              rules={{ required: t("required") }}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("organizationType")}
                  value={value}
                  onChange={onChange}
                  fullWidth
                >
                  <MenuItem value="Autohaus">{t("autohaus")}</MenuItem>
                  <MenuItem value="CarPool">{t("carpool")}</MenuItem>
                  <MenuItem value="Expert">{t("expert")}</MenuItem>
                  <MenuItem value="Workshop">{t("workshop")}</MenuItem>
                  <MenuItem value="LegalOffice">{t("legaloffice")}</MenuItem>
                </Select>
              )}
            />
            {errors.organizationType && (
              <Typography color="error">{t("required")}</Typography>
            )}
          </FormControl>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: t("required"),
              validate: validatePhoneNumber,
            }}
            render={({ field: { value, onChange } }) => (
              <PhoneNumberInput
                fullWidth
                value={value}
                onChange={onChange}
                label={t("mobilePhone")}
                error={!!errors?.phone}
                helperText={errors?.phone?.message as any}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("required"),
              pattern: {
                value: EMAIL_REGEX_VALIDATION,
                message: t("emailNotValid"),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                label={t("email")}
                error={!!errors?.email}
                helperText={errors?.email?.message as any}
              />
            )}
          />
          <Controller
            name="address.street"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("street")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="houseNumber"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("houseNumber")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <PlaceController
            formControl={control as unknown as Control<FieldValues>}
            field={
              {
                controlName: "address.postCodePlace",
                label: t("postalCodeOrCity"),
              } as any
            }
            disabled={false}
            rules={{ required: t("required") }}
          />
          <Controller
            name="externalDetails.addressNr"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("required"),
              },
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("addressNr")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.referat"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("required"),
              },
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("referat")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.lawyer"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("lawyer")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.contactPersonFullName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("contactPersonFullName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.contactPersonId"
            control={control}
            rules={{
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("contactPersonId")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onFormSubmit)}
          loading={isSubmitting || isUpdating}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
