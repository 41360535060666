import { axios, axiosWorkflow } from "../lib"

export const getGlobalInboxAPI = (
  params: IGetGlobalInboxRequestParams,
): Promise<IGetGlobalInboxResponse> =>
  axiosWorkflow.post("/Listener/globalInbox", params)

export const getGlobalInboxAttachmentsAPI = (
  params: IGetGlobalInboxAttachmentsRequestParams,
): Promise<IGetGlobalInboxAttachmentsResponse> =>
  axiosWorkflow.get("/Listener/attachment", { params })

export const getGlobalInboxDeletedAPI = (
  params: IGetGlobalInboxRequestParams,
): Promise<IGetGlobalInboxResponse> =>
  axiosWorkflow.post("/Listener/deleted", params)

export const getDocTypesAPI = (): Promise<IDocType[]> =>
  axiosWorkflow.get("/Listener/docTypes")

export const postAssignCaseAPI = (
  body: IPostGlobalInboxAssignCaseRequestBody,
): Promise<void> => axiosWorkflow.post("/Listener/assignCase", body)

export const getSearchClaimsWithDetailsAPI = (
  params: IGetSearchClaimsWithDetailsRequestParams,
): Promise<ISearchClaimsWithDetails[]> =>
  axios.get("/Claim/claimsWithDetails", { params })

export const postGlobalInboxAssignDocTypeAPI = (
  body: IGlobalInboxAssignDocTypeRequestBody,
): Promise<void> => axiosWorkflow.post("/Listener/assignDocType", body)

export const deleteGlobalInboxEmailAPI = (id: string): Promise<void> =>
  axiosWorkflow.delete("/Listener", { params: { id } })

export const deleteGlobalInboxAttachmentAPI = (
  body: IDeleteGlobalInboxAttachmentRequestBody,
): Promise<void> => axiosWorkflow.delete("/Listener/document", { data: body })
