import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { colors } from "../../../../utils"
import { TabOption, TabsMenu } from "./styled"

interface IProps {
  activeTab: string
  onTabChange: (tab: string) => void
}

export const TabMenu = (props: IProps) => {
  const { activeTab, onTabChange } = props
  const { t } = useTranslation()

  return (
    <Box borderBottom={`1px solid ${colors.gray4}`} margin="0 24px">
      <TabsMenu
        variant="scrollable"
        value={activeTab}
        onChange={(_, value) => onTabChange(value)}
      >
        <TabOption label={t("global")} value="global" />
        <TabOption label={t("group")} value="group" disabled />
        <TabOption label={t("user")} value="user" disabled />
        <TabOption label={t("private")} value="private" disabled />
        <TabOption label={t("blacklisted")} value="blacklisted" />
        <TabOption label={t("trash")} value="trash" />
      </TabsMenu>
    </Box>
  )
}
