import { Box, Typography, TextField, Button, IconButton } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { colors } from "../../../../../../utils"
import {
  getAllChannelConfigurationsAPI,
  getScopeDefinion,
} from "../../../../../../services"
import { Search, RemoveCircle } from "@mui/icons-material"
import {
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"
import { Accord } from "./Accordion"
import {
  SelectionBox,
  SelectionWrapper,
  styledAccordionWrapper,
  styledButton,
  styledRemoveButton,
} from "./styled"

interface IProps {
  triggerType: TChannelTypeName
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: any,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  selectedAction: INodeItem
  selections: Record<string, boolean>
  setSelections: Dispatch<SetStateAction<Record<string, boolean>>>
  selectAll: Record<string, boolean>
  setSelectAll: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
}

const CHANNEL_ID = "ChannelId"
const CHANNEL_TYPE = "ChannelType"
const DOC_TYPE = "DocType"
const LAWYER = "Lawyer"
const REFERATE = "Referate"

export const GeneralTrigger = (props: IProps) => {
  const {
    triggerType,
    handleChange,
    selectedAction,
    selections,
    setSelections,
    selectAll,
    setSelectAll,
  } = props
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>("")

  const [currentExpanded, setCurrentExpanded] = useState<
    "channels" | "docTypes" | "channelType" | "lawyer" | "referate" | ""
  >("")

  const {
    data: channelConfigurations,
    isPending: isPendingConfigurations,
    isRefetching: isRefetchingConfigurations,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: () => getAllChannelConfigurationsAPI(),
    refetchOnMount: true,
  })

  const { data: scopeDefinition } = useQuery({
    queryKey: ["scope-definition"],
    queryFn: () => getScopeDefinion(),
    refetchOnMount: true,
  })

  const channels = selectedAction?.inputs?.Scope?.[CHANNEL_ID] || [""]
  const docs = selectedAction?.inputs?.Scope?.[DOC_TYPE] || [""]
  const lawyer = selectedAction?.inputs?.Scope?.[LAWYER] || [""]
  const referate = selectedAction?.inputs?.Scope?.[REFERATE] || [""]
  const channelType = selectedAction?.inputs?.Scope?.[CHANNEL_TYPE] || [""]

  const sortedReferate = scopeDefinition?.Referate
  ?.slice()
  .sort((a, b) => {
    return Number(a.scopeValue) - Number(b.scopeValue);
  }) || [];

  const setInitialSelectAll: (
    querySource: any[] | undefined,
    source: any[],
    type: string,
  ) => void = (querySource, source, type) => {
    if (type !== "channelType") {
      setSelectAll((prev: any) => {
        return {
          ...prev,
          [type]: querySource
            ?.map((item) => item.id)
            .every((id) => source?.includes(id)),
        }
      })
    } else {
      setSelectAll((prev: any) => {
        return {
          ...prev,
          [type]: querySource
            ?.map((document) => document.scopeValue)
            .every((scopeValue) => source?.includes(scopeValue)),
        }
      })
    }
  }

  const filterItems = (items: any, type: string) => {
    return search
      ? items?.filter((item: any) =>
          type === "channels"
            ? item?.channelName?.toLowerCase()?.includes(search?.toLowerCase())
            : item?.scopeValue?.toLowerCase()?.includes(search?.toLowerCase()),
        )
      : items
  }

  const useFilteredItems = (
    itemsArray: TScopeDefinitionItem[] | IChannelConfiguration[] | undefined,
    type: string,
    search: string,
  ) => {
    return useMemo(() => filterItems(itemsArray, type), [itemsArray, search])
  }

  const filteredChannels = useFilteredItems(
    channelConfigurations,
    "channels",
    search,
  )
  const filteredDocTypes = useFilteredItems(
    scopeDefinition?.DocType,
    "docTypes",
    search,
  )
  const filteredLawyer = useFilteredItems(
    scopeDefinition?.Lawyer,
    "lawyer",
    search,
  )
  const filteredReferate = useFilteredItems(
    sortedReferate,
    "referate",
    search,
  )
  const filteredChannelType = useFilteredItems(
    scopeDefinition?.ChannelType,
    "channelType",
    search,
  )

  const initialArr = [
    {
      0: scopeDefinition?.ChannelType,
      1: channelType,
      2: "channelType",
      4: filteredChannelType,
      5: CHANNEL_TYPE,
      selection: "Channel Type",
    },
    {
      0: channelConfigurations,
      1: channels,
      2: "channels",
      4: filteredChannels,
      5: CHANNEL_ID,
      selection: "Channels",
    },
    {
      0: scopeDefinition?.DocType,
      1: docs,
      2: "docTypes",
      4: filteredDocTypes,
      5: DOC_TYPE,
      selection: "Document Type",
    },
    {
      0: scopeDefinition?.Lawyer,
      1: lawyer,
      2: "lawyer",
      4: filteredLawyer,
      5: LAWYER,
      selection: "Lawyer",
    },
    {
      0: sortedReferate,
      1: referate,
      2: "referate",
      4: filteredReferate,
      5: REFERATE,
      selection: "Referate",
    },
  ]

  useEffect(() => {
    setSelections(() => {
      const previouslySelected = {
        "Channel Type": channelType[0] !== "",
        Channels: channels[0] !== "",
        "Document Type": docs[0] !== "",
        Lawyer: lawyer[0] !== "",
        Referate: referate[0] !== "",
      }

      return previouslySelected
    })
  }, [triggerType])

  const isLoading = isPendingConfigurations || isRefetchingConfigurations

  return (
    <Box>
      <SelectionWrapper>
        <Typography sx={{ ml: 1 }} variant="largeMedium">
          {t("step")} {t("id")}: {selectedAction?.id}
        </Typography>

        <TextField
          sx={{
            visibility: Object.values(selections).includes(true)
              ? "visible"
              : "hidden",
          }}
          label={t("search")}
          variant="outlined"
          size="small"
          disabled={isLoading}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{ startAdornment: <Search htmlColor={colors.gray2} /> }}
        />
      </SelectionWrapper>
      {Object.values(selections).includes(false) && (
        <SelectionBox>
          {Object.keys(selections).map((sel) => {
            if (triggerType !== "Email" && sel === "Document Type") {
              return null
            }
            return !selections[sel] ? (
              <Button
                onClick={() => {
                  setSelections((prev) => ({ ...prev, [sel]: true }))
                  initialArr?.forEach((item) => {
                    if (item[0]) {
                      setInitialSelectAll(item[0], item[1], item[2])
                    }
                  })
                }}
                sx={styledButton}
                variant="outlined"
              >
                <AddIcon sx={{ mr: 1 }} />
                {sel}
              </Button>
            ) : (
              <></>
            )
          })}
        </SelectionBox>
      )}
      {initialArr.map((source, i) => {
        if (selections[source.selection]) {
          return (
            <Box key={i} sx={styledAccordionWrapper}>
              <Accord
                currentExpanded={currentExpanded}
                setCurrentExpanded={setCurrentExpanded}
                items={source[4]}
                source={source[1]}
                type={source[2]}
                selectAllItems={selectAll}
                setSelectAllItems={setSelectAll}
                querySource={source[0]}
                selectedAction={selectedAction}
                handleChange={handleChange}
                changeType={source[5]}
              />
              <IconButton
                onClick={() =>
                  setSelections((prev) => ({ ...prev, "Channel Type": false }))
                }
                color="error"
                sx={styledRemoveButton}
              >
                <RemoveCircle />
              </IconButton>
            </Box>
          )
        } else return null
      })}
    </Box>
  )
}
