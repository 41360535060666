import React, { useCallback, useRef } from "react"
import { colors } from "../../../../../../utils"
import {
  MenuItem,
  CircularProgress,
  Box,
  type SxProps,
  type Theme,
} from "@mui/material"
import { axiosWorkflow } from "../../../../../../lib"
import { useToast } from "../../../../../../contexts"
import { t } from "i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"

interface FileUploadProps {
  allowMultiple: boolean
  allowedExtensions: string[]
  sx?: SxProps<Theme>
  handleUpload?: (link: string) => void
}

const MenuItemFileUpload: React.FC<FileUploadProps> = ({
  allowMultiple,
  allowedExtensions,
  handleUpload,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const queryClient = useQueryClient()

  const uploadFile = (file: File): Promise<string> => {
    const formData = new FormData()
    formData.append("file", file)

    return axiosWorkflow.post("/File", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  const memoizedHandleUpload = useCallback(
    (link: string) =>
      handleUpload === undefined ? undefined : handleUpload(link),
    [handleUpload],
  )

  const { mutate: fileUpload, isPending: isUploading } = useMutation({
    mutationFn: (file: File) => uploadFile(file),
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({
        queryKey: ["workflow-file-templates"],
      })

      memoizedHandleUpload(response)
      toast.show(t("fileUploaded"), "success")
    },
    onError: () => {
      toast.show(t("fileUploadFail"), "error")
    },
  })

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target === undefined) return
    const file = event.target.files?.[0]
    if (file && !isUploading) {
      fileUpload(file)
    }
  }

  const handleMenuClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <MenuItem
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: colors.primary,
        fontWeight: 700,
        textAlign: "center",
      }}
      key="fileUpload"
      value="fileUpload"
      onClick={!isUploading ? handleMenuClick : undefined}
    >
      {isUploading ? (
        <CircularProgress size={20} style={{ padding: "5px" }} />
      ) : (
        ""
      )}
      <Box sx={{ fontSize: 17.5 }} component="span">
        {t("uploadTemplate")}
        <Box
          component="span"
          sx={{
            fontSize: 17.5,
            fontWeight: 500,
            color: "black",
            ml: 1,
          }}
        >
          or drag and drop
        </Box>
        <Box sx={{ fontSize: 17.5, color: "black", fontWeight: 500 }}>
          (Max. File Size xxxMB)
        </Box>
      </Box>

      <Box
        component="input"
        display="none"
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        disabled={isUploading}
        accept={allowedExtensions.join(",")}
        multiple={allowMultiple}
      />
    </MenuItem>
  )
}

export default MenuItemFileUpload
