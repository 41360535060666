import { axiosWorkflow } from "../lib"

export const getWorkflowDefinitionsAPI = (
  params: IGetWorkflowDefinitionsParams,
): Promise<IGetWorkflowDefinitionsResponse> =>
  axiosWorkflow.get("/Definition", { params })

export const getWorkflowDefinitionAPI = (
  id: string,
  params: Record<string, string>,
): Promise<IWorkflowDefinitionRes> =>
  axiosWorkflow.get(`/Definition/${id}`, { params })

export const postWorkflowDefinitionAPI = (
  body: IWorkflowDefinition,
): Promise<IWorkflowDefinitionRes> => axiosWorkflow.post("/Definition", body)

export const activateDefinitionVersion = (
  id: string,
  version: number,
): Promise<any> => axiosWorkflow.post(`/Definition/${id}/${version}/activate`)

export const updateWorkflowDefinitionAPI = (
  body: IWorkflowDefinition,
): Promise<IWorkflowDefinitionRes> =>
  axiosWorkflow.post(`/Definition/${body.id}/update`, body)

export const deleteWorkflowDefinitionAPI = (
  id: string,
  params: Record<string, number>,
): Promise<any> => axiosWorkflow.delete(`/Definition/${id}`, { params })

export const hasWorkflowInstances = (
  id: string,
  version: number,
): Promise<boolean> =>
  axiosWorkflow.get(`/Workflow/${id}/${version}/has-workflow-instances`)

export const getWorkflowsAPI = (
  params: IGetWorkflowsParams,
): Promise<IGetWorkflowsResponse> => axiosWorkflow.get("/Workflow", { params })

export const getWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.get(`/Workflow/${id}`)

export const postWorkflowAPI = (
  requestParams: IPostWorkflowRequest,
): Promise<IWorkflow> =>
  axiosWorkflow.post(
    `/Workflow/${requestParams.definitionId}`,
    requestParams.body,
  )

export const suspendWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.put(`/Workflow/${id}/suspend`)

export const resumeWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.post(`/Workflow/${id}/resume`)

export const postWorkflowEventAPI = (
  requestParams: IPostEventRequest,
): Promise<void> =>
  axiosWorkflow.post(
    `/Event/${requestParams.eventName}/${requestParams.eventKey}`,
    requestParams.body,
  )

export const getWorkflowEventAPI = (
  requestParams: IGetEventRequest,
): Promise<IGetEventResponse> =>
  axiosWorkflow.get(
    `/Event/${requestParams.eventName}/${requestParams.eventKey}`,
  )

export const getTemplateList = (): Promise<any> => {
  return axiosWorkflow.get("/File/templates")
}
