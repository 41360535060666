import { type UseMutateFunction } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import { useCallback } from "react"

interface IProps {
  open: boolean
  modifiedData: IWorkflowDefinitionRes
  isSubmitting: boolean
  onClose: () => void
  mutate: UseMutateFunction<
    IWorkflowDefinitionRes,
    Error,
    IWorkflowDefinition,
    unknown
  >
}

export const VersionConfirmationModal = (props: IProps) => {
  const { open, modifiedData, isSubmitting, onClose, mutate } = props
  const { t } = useTranslation()

  const handleSubmit = useCallback(() => {
    mutate({
      ...modifiedData,
      active: false,
    })
  }, [modifiedData, mutate])

  return (
    <>
      <Dialog
        component="form"
        open={open}
        maxWidth="sm"
        onClose={() => onClose()}
      >
        <DialogTitle>{t("warning")}</DialogTitle>
        <DialogContent>
          <Typography whiteSpace="pre-line" variant="large">
            {t("runningWorkflowWithDefinitionMessage")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} variant="outlined">
            {t("cancel")}
          </Button>
          <LoadingButton loading={isSubmitting} onClick={handleSubmit}>
            {t("continue")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
