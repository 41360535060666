import { useTranslation } from "react-i18next"
import { ItemBox, SelectDropdown } from "./styled"
import { Check } from "@mui/icons-material"
import { MenuItem } from "@mui/material"

interface IProps {
  value: string[]
  onChange: (event: any) => void
}

const getChannelText = (channel: string) => {
  switch (channel) {
    case "1":
      return "email"
    case "2":
      return "database"
    case "3":
      return "interface"
    default:
      return ""
  }
}

export const ChannelDropdown = (props: IProps) => {
  const { value, onChange } = props
  const { t } = useTranslation()

  return (
    <SelectDropdown
      multiple
      value={value}
      onChange={onChange}
      label={t("switchChannel")}
      renderValue={(selected: any) => {
        return selected
          .map((channel: string) => t(getChannelText(channel)))
          .join(", ")
      }}
    >
      <MenuItem value={undefined}>
        <i>{t("all")}</i>
      </MenuItem>
      <MenuItem value="1">
        <ItemBox>
          {t("email")}
          {value.includes("1") && <Check color="primary" />}
        </ItemBox>
      </MenuItem>
      <MenuItem value="2">
        <ItemBox>
          {t("database")} {value.includes("2") && <Check color="primary" />}
        </ItemBox>
      </MenuItem>
      <MenuItem value="3">
        <ItemBox>
          {t("interface")} {value.includes("3") && <Check color="primary" />}
        </ItemBox>
      </MenuItem>
    </SelectDropdown>
  )
}
