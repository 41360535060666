import { Delete } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps {
  isOpen: boolean
  isDeleting: boolean
  onClose: () => void
  onDelete: () => void
}

export const DeleteDefinitionModal = (props: IProps) => {
  const { isOpen, isDeleting, onClose, onDelete } = props
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("deleteDefinition")}</DialogTitle>
      <DialogContent>{t("deleteDefinitionConfirmationMessage")}</DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          size="small"
          color="error"
          loading={isDeleting}
          startIcon={<Delete />}
          onClick={onDelete}
        >
          {t("delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
