import React, {
  type ChangeEventHandler,
  useCallback,
  useState,
  useEffect,
} from "react"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"
import { getWorkflowDefinitionsAPI } from "../../services"
import { AccountTreeOutlined, Search } from "@mui/icons-material"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import {
  CustomRow,
  EmptyRow,
  EnhancedTable,
  EnhancedTableContainer,
  NoDataRow,
} from "./styled"
import { EnhancedTableHead } from "./components"
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import dayjs from "dayjs"

export const WorkflowDefinitionsListingPage = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({ pageSize: 10, page: 0 })
  const [searchKey, setSearchKey] = useState("")
  const [sortBy, setSortBy] = useState("Version")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")

  const navigate = useNavigate()

  const {
    data: definitions,
    isPending,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ["workflow-definitions"],
    queryFn: () =>
      getWorkflowDefinitionsAPI({
        activeVersionOnly: true,
        page: filters.page + 1,
        pageSize: filters.pageSize,
        searchQuery: searchKey,
        sortBy,
        sortDesc: sortOrder === "desc",
      }),
    refetchOnMount: true,
    enabled: false,
  })

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKey(event.target.value)
    },
    [],
  )

  const handleSort = useCallback(
    (column: string) => {
      setSortBy(column)
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    },
    [sortOrder],
  )

  const isDataLoading = isPending || isRefetching

  const onPageChange = useCallback(
    (_: unknown, page: number) => setFilters((prev) => ({ ...prev, page })),
    [],
  )

  const onPageSizeChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) =>
      setFilters((prev) => ({ ...prev, pageSize: +event.target.value })),
    [],
  )

  useEffect(() => {
    if (searchKey) {
      const handler = setTimeout(() => {
        void refetch()
      }, 500)

      return () => {
        clearTimeout(handler)
      }
    } else {
      void refetch()
    }
  }, [filters, searchKey, sortBy, sortOrder])

  useEffect(() => {
    if (definitions && definitions.currentPage > definitions.totalPages) {
      setFilters((prev) => ({ ...prev, page: 0 }))
    }
  }, [definitions])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        paddingBottom="24px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="12px"
          padding="24px 24px 0 24px"
        >
          <Typography variant="h4">{t("definitionsListing")}</Typography>
          <Button
            onClick={() => navigate("/workflow-configurator/newDefinition/0")}
          >
            {t("newDefinition")}
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="0 24px 12px 24px"
        >
          <Typography
            variant="regular"
            color={colors.gray3}
            className="white-space-pre-line"
            sx={{ whiteSpace: "pre-line" }}
          >
            {t("workflowDefinitionsDescription")}
          </Typography>
          <TextField
            variant="outlined"
            placeholder={t("search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Box>
        <Box display="grid">
          <EnhancedTableContainer>
            <EnhancedTable>
              <EnhancedTableHead
                sort={sortBy}
                order={sortOrder}
                onSort={handleSort}
              />
              <TableBody>
                {isDataLoading ? (
                  <>
                    <EmptyRow>
                      <TableCell colSpan={10} height="16px" />
                    </EmptyRow>
                    {Array.from({ length: filters.pageSize }).map(
                      (_, index) => (
                        <React.Fragment key={index}>
                          <CustomRow>
                            {Array.from({ length: 5 }).map((_, index) =>
                              index === 2 || index === 3 ? (
                                <TableCell key={index}>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    paddingX="8px"
                                    paddingY="6px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      gap="4px"
                                    >
                                      <PeopleOutlineOutlinedIcon fontSize="small" />
                                      -
                                      <Skeleton width="60%" />
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      marginTop="4px"
                                      gap="4px"
                                    >
                                      <CalendarMonthOutlinedIcon fontSize="small" />
                                      -
                                      <Skeleton width="60%" />
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell key={index}>
                                  <Skeleton sx={{ mr: "12px" }} />
                                </TableCell>
                              ),
                            )}
                            <TableCell>
                              <Skeleton
                                variant="rounded"
                                width="14px"
                                sx={{ ml: "auto" }}
                              />
                            </TableCell>
                          </CustomRow>
                          <EmptyRow>
                            <TableCell colSpan={10} height="16px" />
                          </EmptyRow>
                        </React.Fragment>
                      ),
                    )}
                  </>
                ) : definitions?.totalItems === 0 ? (
                  <>
                    <EmptyRow>
                      <TableCell colSpan={10} height="16px" />
                    </EmptyRow>
                    <NoDataRow>
                      <TableCell colSpan={10}>
                        <Typography>{t("noData")}</Typography>
                      </TableCell>
                    </NoDataRow>
                  </>
                ) : (
                  <>
                    <EmptyRow>
                      <TableCell colSpan={10} height="16px" />
                    </EmptyRow>
                    {definitions?.items?.map((definition, index) => (
                      <React.Fragment key={index}>
                        <CustomRow>
                          <TableCell>{definition.id}</TableCell>
                          <TableCell>{definition.version}</TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              flexDirection="column"
                              paddingX="8px"
                              paddingY="6px"
                            >
                              <Box display="flex" alignItems="center">
                                <PeopleOutlineOutlinedIcon fontSize="small" />
                                <Typography
                                  variant="small"
                                  className="line-clamp-1"
                                  color={colors.gray9}
                                  marginLeft="4px"
                                >
                                  {definition.createdBy !== null &&
                                  definition.createdBy
                                    ? definition.createdBy.name + " " + definition.createdBy.surname
                                    : "/"}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                marginTop="4px"
                              >
                                <CalendarMonthOutlinedIcon fontSize="small" />
                                <Typography
                                  variant="small"
                                  className="line-clamp-1"
                                  color={colors.gray9}
                                  marginLeft="4px"
                                >
                                  {definition?.createdAt
                                    ? dayjs(definition.createdAt).format(
                                        "DD.MM.YYYY, HH:mm",
                                      )
                                    : "/"}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              flexDirection="column"
                              paddingX="8px"
                              paddingY="6px"
                            >
                              <Box display="flex" alignItems="center">
                                <PeopleOutlineOutlinedIcon fontSize="small" />
                                <Typography
                                  variant="small"
                                  className="line-clamp-1"
                                  color={colors.gray9}
                                  marginLeft="4px"
                                >
                                  {definition.updatedBy !== null &&
                                  definition.updatedBy
                                    ? definition.updatedBy.name + " " + definition.updatedBy.surname
                                    : "/"}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                marginTop="4px"
                              >
                                <CalendarMonthOutlinedIcon fontSize="small" />
                                <Typography
                                  variant="small"
                                  className="line-clamp-1"
                                  color={colors.gray9}
                                  marginLeft="4px"
                                >
                                  {definition?.updatedAt
                                    ? dayjs(definition.updatedAt).format(
                                        "DD.MM.YYYY, HH:mm",
                                      )
                                    : "/"}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="small"
                              className="line-clamp-2"
                            >
                              {definition.description}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={`/workflow-configurator/${definition.id}/${definition.version}`}
                            >
                              <IconButton>
                                <AccountTreeOutlined fontSize="small" />
                              </IconButton>
                            </Link>
                          </TableCell>
                        </CustomRow>
                        <EmptyRow>
                          <TableCell colSpan={10} height="16px" />
                        </EmptyRow>
                      </React.Fragment>
                    ))}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={definitions?.totalItems ?? 0}
                    page={filters.page}
                    rowsPerPage={filters.pageSize}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onPageSizeChange}
                    labelRowsPerPage={t("rowsPerPage")}
                  />
                </TableRow>
              </TableFooter>
            </EnhancedTable>
          </EnhancedTableContainer>
        </Box>
      </Box>
    </Box>
  )
}
